var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center primary--text text-h6 metropolis font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("pesajes.formulario.title"))+" ")])],1),_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"4"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',{staticClass:"metropolis font-weight-bold",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t("pesajes.formulario.vehiculo.label"))+" ")]),_c('v-col',{staticClass:"pa-0 error--text metropolis",attrs:{"cols":"auto"}},[_vm._v(" * ")])],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{staticClass:"elevation-input-dense metropolis rounded-input",attrs:{"solo":"","dense":"","return-object":"","color":"primary","item-text":"patente","hide-details":"auto","placeholder":_vm.t('pesajes.formulario.vehiculo.placeholder'),"items":_vm.arrayVehiculosPesaje,"error-messages":_vm.erroresVehiculo,"menu-props":{
            contentClass: 'rounded-input elevation-black',
            transition: 'slide-y-transition',
          }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{staticClass:"metropolis darker--text text-uppercase"},[_vm._v(" "+_vm._s(item.patente)+" ")])]}},{key:"selection",fn:function({ item }){return [_c('span',{staticClass:"metropolis text-uppercase text-truncate darker--text"},[_vm._v(" "+_vm._s(item.patente)+" ")])]}},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-chevron-down ")])]},proxy:true}]),model:{value:(_vm.vehiculo),callback:function ($$v) {_vm.vehiculo=$$v},expression:"vehiculo"}})],1)],1),_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"4"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',{staticClass:"metropolis font-weight-bold",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t("pesajes.formulario.cliente.label"))+" ")]),_c('v-col',{staticClass:"pa-0 error--text metropolis",attrs:{"cols":"auto"}},[_vm._v(" * ")])],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{staticClass:"elevation-input-dense metropolis rounded-input",attrs:{"solo":"","dense":"","return-object":"","color":"primary","item-text":"nombre","hide-details":"auto","placeholder":_vm.t('pesajes.formulario.cliente.placeholder'),"items":_vm.arrayClientesPesaje,"error-messages":_vm.erroresCliente,"menu-props":{
            contentClass: 'rounded-input elevation-black',
            transition: 'slide-y-transition',
          }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{staticClass:"metropolis darker--text text-capitalize"},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"selection",fn:function({ item }){return [_c('span',{staticClass:"metropolis text-capitalize text-truncate darker--text"},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-chevron-down ")])]},proxy:true}]),model:{value:(_vm.cliente),callback:function ($$v) {_vm.cliente=$$v},expression:"cliente"}})],1)],1),_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"4"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',{staticClass:"metropolis font-weight-bold",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t("pesajes.formulario.desecho.label"))+" ")]),_c('v-col',{staticClass:"pa-0 error--text metropolis",attrs:{"cols":"auto"}},[_vm._v(" * ")])],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{staticClass:"elevation-input-dense metropolis rounded-input",attrs:{"solo":"","dense":"","hide-details":"","return-object":"","color":"primary","item-text":"nombre","placeholder":_vm.t('pesajes.formulario.desecho.placeholder'),"items":_vm.arrayDesechosPesaje,"error-messages":_vm.erroresDesecho,"menu-props":{
            contentClass: 'rounded-input elevation-black',
            transition: 'slide-y-transition',
          }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{staticClass:"metropolis darker--text text-capitalize"},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"selection",fn:function({ item }){return [_c('span',{staticClass:"metropolis text-capitalize text-truncate darker--text"},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-chevron-down ")])]},proxy:true}]),model:{value:(_vm.desecho),callback:function ($$v) {_vm.desecho=$$v},expression:"desecho"}})],1)],1),_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"4"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',{staticClass:"metropolis font-weight-bold",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t("pesajes.formulario.servicio.label"))+" ")]),_c('v-col',{staticClass:"pa-0 error--text metropolis",attrs:{"cols":"auto"}},[_vm._v(" * ")])],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{staticClass:"elevation-input-dense metropolis rounded-input",attrs:{"solo":"","dense":"","return-object":"","color":"primary","item-text":"nombre","hide-details":"auto","placeholder":_vm.t('pesajes.formulario.servicio.placeholder'),"items":_vm.arrayServiciosPesaje,"error-messages":_vm.erroresServicio,"menu-props":{
            contentClass: 'rounded-input elevation-black',
            transition: 'slide-y-transition',
          }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{staticClass:"metropolis darker--text text-capitalize"},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"selection",fn:function({ item }){return [_c('span',{staticClass:"metropolis text-capitalize text-truncate darker--text"},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-chevron-down ")])]},proxy:true}]),model:{value:(_vm.servicio),callback:function ($$v) {_vm.servicio=$$v},expression:"servicio"}})],1)],1),_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"4"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',{staticClass:"metropolis font-weight-bold",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t("pesajes.formulario.ruta.label"))+" ")]),_c('v-col',{staticClass:"pa-0 error--text metropolis",attrs:{"cols":"auto"}},[_vm._v(" * ")])],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{staticClass:"elevation-input-dense metropolis rounded-input",attrs:{"solo":"","dense":"","return-object":"","color":"primary","item-text":"nombre","hide-details":"auto","placeholder":_vm.t('pesajes.formulario.ruta.placeholder'),"items":_vm.arrayRutasPesajePesaje,"error-messages":_vm.erroresRutaPesaje,"menu-props":{
            contentClass: 'rounded-input elevation-black',
            transition: 'slide-y-transition',
          }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{staticClass:"metropolis darker--text text-capitalize"},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"selection",fn:function({ item }){return [_c('span',{staticClass:"metropolis text-capitalize text-truncate darker--text"},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-chevron-down ")])]},proxy:true}]),model:{value:(_vm.rutaPesaje),callback:function ($$v) {_vm.rutaPesaje=$$v},expression:"rutaPesaje"}})],1)],1),_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":""}},[_c('v-col',[_c('EntradaSalidaEditar',{attrs:{"pesaje":_vm.pesaje,"mostrar-galeria":_vm.mostrarGaleria,"editar-galeria":_vm.editarGaleria,"arrayChoferes":_vm.arrayChoferesPesaje,"cargando":_vm.cargando},on:{"click:editar-imagenes":_vm.onClickEditarImagenes,"click:guardar":_vm.clickGuardar}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }