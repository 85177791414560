var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"space-between","align":"center"}},[_c('v-col',{class:{
          'metropolis font-weight-bold': true,
          'darker--text': !_vm.dialog,
          'white--text': _vm.dialog
        },attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),(_vm.required)?_c('v-col',{staticClass:"pa-0 error--text metropolis",attrs:{"cols":"auto"}},[_vm._v(" * ")]):_vm._e()],1)],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"9"}},[_c('v-select',{class:{
        'rounded-input metropolis': true,
        'elevation-input-dense': !_vm.dialog,
        'border-input': _vm.dialog
      },attrs:{"solo":!_vm.dialog,"outlined":_vm.dialog,"dense":"","hide-details":"auto","placeholder":_vm.placeholder,"menu-props":{
        contentClass: 'rounded-input elevation-black',
        transition: 'slide-y-transition',
      },"error-messages":_vm.errorMessages,"items":_vm.items,"item-text":_vm.itemText,"item-value":_vm.itemValue,"return-object":_vm.returnObject,"clearable":_vm.clearable,"readonly":_vm.readonly,"disabled":_vm.disabled,"dark":_vm.dialog},on:{"input":_vm.onInput,"change":_vm.onChange,"focus":_vm.onFocus,"blur":_vm.onBlur},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{class:`metropolis darker--text text-${_vm.textCase}`},[_vm._v(" "+_vm._s(_vm.getText(item))+" ")])]}},{key:"selection",fn:function({ item }){return [_c('span',{class:`metropolis ${_vm.dialog ? 'white' : 'darker'}--text text-${_vm.textCase}`},[_vm._v(" "+_vm._s(_vm.getText(item))+" ")])]}},{key:"append",fn:function(){return [(_vm.dialog)?_c('div',{staticClass:"icon-container"},[_c('svg-icon',{attrs:{"name":"ionic-md-arrow-dropdown","color":"text","width":"12px","height":"12px"}})],1):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-chevron-down ")])]},proxy:true}]),model:{value:(_vm.vModel),callback:function ($$v) {_vm.vModel=$$v},expression:"vModel"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }