const fechaISO = (fecha = new Date()): string => {
  return (
    fecha.getFullYear().toString() +
    "-" +
    (fecha.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    fecha
      .getDate()
      .toString()
      .padStart(2, "0")
  );
};

const fechaISOApple = (fecha = new Date()): string => {
  return (
    fecha.getFullYear().toString() +
    "/" +
    (fecha.getMonth() + 1).toString().padStart(2, "0") +
    "/" +
    fecha
      .getDate()
      .toString()
      .padStart(2, "0")
  );
};

const horaISO = (fecha = new Date()): string => {
  return (
    fecha
      .getHours()
      .toString()
      .padStart(2, "0") +
    ":" +
    fecha
      .getMinutes()
      .toString()
      .padStart(2, "0") +
    ":" +
    fecha
      .getSeconds()
      .toString()
      .padStart(2, "0")
  );
};

const fechaLocal = (fechaString: string | undefined): string | null => {
  if (!fechaString) {
    return null;
  }
  const date = new Date(fechaString);
  return date.toLocaleDateString();
};

const meses = (fechaInicio = new Date(), fechaFin = new Date()): number => {
  return (
    (fechaFin.valueOf() - fechaInicio.valueOf()) /
    (1000 * 60 * 60 * 24 * 30.4375)
  );
};

const minutos = (fechaInicio = new Date(), fechaFin = new Date()): number => {
  return (fechaFin.valueOf() - fechaInicio.valueOf()) / (1000 * 60);
};

const isDate = (value: string): boolean => {
  const prueba = new Date(value);
  return prueba.toString() != "Invalid Date";
};

const date = {
  fechaISO,
  horaISO,
  fechaLocal,
  meses,
  minutos,
  isDate,
  fechaISOApple,
};

export default date;

export { fechaISO, fechaLocal, meses, minutos, horaISO, isDate, fechaISOApple };
