// composition-api
import { ref } from "vue";
// router
import router from "@/router";

router.afterEach((route) => {
  routeName.value = route.name ?? "";
});

const routeName = ref("");

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useRouter() {
  return { routeName };
}
