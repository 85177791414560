export {};

declare global {
  interface String {
    toDate(): Date;

    toInt(): number;

    toRUT(): string;

    validateRUT(): boolean;

    validateEmail(): boolean;

    capitalizeFirstLetter(): string;

    capitalizeAll(): string;
  }

  interface Number {
    toCurrency(symbol?: string): string;
  }

  interface Date {
    format(format: string): string;
  }
}

Number.prototype.toCurrency = function (symbol) {
  if (!isNaN(Number(this))) {
    let result = "";
    const [number, decimals] = this.toString().split(".");
    const negative = number.includes("-");
    const reverse = number.replace(/-/g, "").split("").reverse().join("");
    const moneyReverse = reverse.match(/.{1,3}/g);
    if(moneyReverse) {
      if (negative) result += "-";
      if (symbol) result += `${symbol} `;
      result += moneyReverse.join(".").split("").reverse().join("");
      if (decimals) result += `,${decimals}`;
      return result;
    }
  }
  return "ERROR";
}

String.prototype.toDate = function () {
  const isoRegexUTC = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(((\.\d+)*Z)|(\+\d{2}:\d{2}))$/;
  const isoRegexNoUTC = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d+$/;
  const stringThis = String(this);
  if (isoRegexNoUTC.test(stringThis) || isoRegexUTC.test(stringThis)) {
    return new Date(stringThis);
  }
  const dateRegex = /\d{2,4}[-/]\d{2}[-/]\d{2,4}/g;
  const hourRegex = /\d{2}:\d{2}(:\d{2})*/g;
  const dateMatch = stringThis.match(dateRegex);
  const hourMatch = stringThis.match(hourRegex);
  let dateString = dateMatch?.[0] ?? new Date().format("YYYY/MM/DD");
  const hourString = hourMatch?.[0] ?? "00:00:00";
  if (/\d{2}[-/]\d{2}[-/]\d{4}/g.test(dateString)) {
    dateString = dateString.split("-").reverse().join("/");
  }
  dateString = dateString.replace(/-/g, "/");
  try {
    return new Date(`${dateString} ${hourString}`);
  } catch (err) {
    console.log(err)
    return new Date();
  }
}

String.prototype.toInt = function () {
  return parseInt(String(this).replace(/[^\d]/g, ""));
}

String.prototype.capitalizeFirstLetter = function () {
  if (this[0]) {
    return `${this[0].toUpperCase()}${this.slice(1, this.length)}`;
  }
  return this.toString();
}

String.prototype.capitalizeAll = function () {
  return this.split(" ").map(t => t.capitalizeFirstLetter()).join(" ");
}

String.prototype.validateRUT = function () {
  const rutRegExp = /^\d{7,8}-[\dkK]$/;
  const rut = this.replace(/\./g, "").toLowerCase();
  if (rutRegExp.test(rut)) {
    const [cuerpo, digitoVerificador] = rut.split("-");
    let suma = 0;
    let multiplicador = 2;
    for (let i = cuerpo.length - 1; i >= 0; i--) {
      if (multiplicador > 7) multiplicador = 2;
      suma += parseInt(cuerpo[i]) * multiplicador;
      multiplicador++;
    }
    const result = 11 - (suma % 11);
    const digitoReal = result == 11 ? 0 : result == 10 ? "k" : result;
    return digitoReal == digitoVerificador;
  }
  return false;
}

String.prototype.validateEmail = function () {
  const EmailRegEx = /[a-zñ][\w-.]*@[a-zñ][\w-.]*\.[a-zñ]+/g;
  return EmailRegEx.test(String(this));
}

String.prototype.toRUT = function () {
  const rutRegExp = /^\d{7,8}-[\dkK]$/;
  if (rutRegExp.test(String(this))) {
    const reverse: string = this.split("-")[0].split("").reverse().join("");
    const rutReverse: RegExpMatchArray | null = reverse.match(/.{1,3}/g);
    if (rutReverse) {
      return `${rutReverse.join(".").split("").reverse().join("")}-${this.split("-")[1]}`;
    }
  }
  return "ERROR";
}

Date.prototype.format = function (format: string) {
  if (this.toString().toLowerCase() !== "invalid date") {
    let fechaReturn = format;
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ];
    const days = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ];
    fechaReturn = fechaReturn.replace("DDDD", days[this.getDay() == 7 ? 0 : this.getDay()]);
    fechaReturn = fechaReturn.replace("DDD", days[this.getDay() == 7 ? 0 : this.getDay()].substring(0, 3));
    fechaReturn = fechaReturn.replace("DD", this.getDate().toString().padStart(2, "0"));
    if (format.includes("MMMM")) {
      fechaReturn = fechaReturn.replace("MMMM", months[this.getMonth()]);
    } else if (format.includes("MMM")) {
      fechaReturn = fechaReturn.replace("MMM", months[this.getMonth()].substring(0, 3));
    } else if (format.includes("MM")) {
      fechaReturn = fechaReturn.replace("MM", (this.getMonth() + 1).toString().padStart(2, "0"));
    }
    if (format.includes("YYYY")) {
      fechaReturn = fechaReturn.replace("YYYY", this.getFullYear().toString());
    } else if (format.includes("YY")) {
      fechaReturn = fechaReturn.replace("YY", this.getFullYear().toString().substring(2, 4));
    }
    fechaReturn = fechaReturn.replace("HH", this.getHours().toString().padStart(2, "0"));
    fechaReturn = fechaReturn.replace("mm", this.getMinutes().toString().padStart(2, "0"));
    fechaReturn = fechaReturn.replace("ss", this.getSeconds().toString().padStart(2, "0"));
    fechaReturn = fechaReturn.replace("tt", this.getHours() >= 12 ? "PM" : "AM");
    return fechaReturn;
  }
  return "Invalid Date"
}
