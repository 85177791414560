// vuex
import { ActionContext, ActionTree, GetterTree, MutationTree } from "vuex";
// tipos
import { State } from "@/typings/store/index";
import { StateSW } from "@/typings/store/modules/sw";

const state: StateSW = {
  updated: false,
  iniciado: false,
};

const actualizar = async (ctx: ActionContext<StateSW, State>) => {
  const updatedRaw = localStorage.getItem("sw/updated");
  if (updatedRaw === "true") {
    ctx.commit("setUpdated", true);
    localStorage.setItem("sw/updated", "false");
  }
  await new Promise((resolve) => setTimeout(resolve, 5000));
  actualizar(ctx);
};

const getters: GetterTree<StateSW, State> = {
  getUpdated(state) {
    return state.updated;
  },
};

const mutations: MutationTree<StateSW> = {
  setUpdated(state, updated) {
    state.updated = updated;
  },
  setIniciado(state, iniciado) {
    state.iniciado = iniciado;
  },
};

const actions: ActionTree<StateSW, State> = {
  iniciar(ctx) {
    if (ctx.state.iniciado) return;
    ctx.commit("setIniciado", true);
    actualizar(ctx);
  },
};

const sw = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default sw;
