// composition-api
import {} from "vue";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useDate() {
  const isDate = (value: string | Date): boolean => {
    const _date = new Date(value);
    return _date.toString() != "Invalid Date";
  };

  const toDate = (date: string | Date | null | undefined): Date | null => {
    if (!date) return null;
    const _date = new Date(date);
    if (!isDate(_date)) return null;
    return _date;
  };

  const newDate = (date: string | Date | null | undefined): Date => {
    const _date = toDate(date);
    return _date ?? new Date();
  };

  return { isDate, toDate, newDate };
}
