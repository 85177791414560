const idtify = (id: string): string => {
  return id.toLowerCase().replace(/[\\./]/gm, "_");
};

const string = {
  idtify,
};

export default string;

export { idtify };
