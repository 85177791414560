// composition-api
import {} from "vue";
// i18n
import i18n from "@/plugins/i18n";
// google maps
import { google } from "vue2-google-maps";
// tipos
import { TDocumentDefinitions } from "pdfmake/interfaces";
import { ExitTicketData } from "@/typings/mixins";

function degreesToRadians(degrees: number): number {
  return (degrees * Math.PI) / 180;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useMixins() {
  const distanceBetween = (
    position1: google.maps.LatLngLiteral,
    position2: google.maps.LatLngLiteral
  ): number => {
    const earthRadiusKm = 6371;
    const dLat = degreesToRadians(position2.lat - position1.lat);
    const dLon = degreesToRadians(position2.lng - position1.lng);
    const lat1 = degreesToRadians(position1.lat);
    const lat2 = degreesToRadians(position2.lat);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earthRadiusKm * c;
  };

  const speedCalculator = (
    date1: Date,
    date2: Date,
    distance: number
  ): number => {
    const time = (date1.getTime() - date2.getTime()) / (1000 * 60 * 60);
    const speed = distance / time;
    if (isNaN(speed)) {
      return 0;
    }
    return speed;
  };

  const msToTime = (duration: number, showSeconds?: boolean): string => {
    const flagSeconds = showSeconds === undefined ? true : showSeconds;
    const seconds = Math.floor((duration / 1000) % 60)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((duration / (1000 * 60)) % 60)
      .toString()
      .padStart(2, "0");
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24)
      .toString()
      .padStart(2, "0");
    const result = [hours, minutes];
    if (flagSeconds) result.push(seconds);
    return result.join(":");
  };

  const $allowedDatesBeforeToday = (date: string): boolean => {
    const fecha = date.toDate();
    const hoy = new Date();
    hoy.setHours(23, 59, 59);
    return hoy.getTime() >= fecha.getTime();
  };

  const $printExitTicket = ({
    empresa,
    qr,
    pesaje,
    m3,
  }: ExitTicketData): TDocumentDefinitions => {
    const diferenciaPesos = pesaje.entrada.peso - pesaje.salida.peso;
    const pesoNeto =
      pesaje.servicio?.tipo === "Egreso"
        ? diferenciaPesos * -1
        : diferenciaPesos;
    return {
      content: [
        {
          text: i18n.t("boleto-pesaje-salida.title") as string,
          fontSize: 16,
          alignment: "center",
        },
        {
          columns: [
            {
              width: "50%",
              margin: [0, 8, 0, 0],
              table: {
                headerRows: 0,
                widths: ["30%", 1, "70%"],
                body: [
                  [
                    {
                      text: i18n.t(
                        "boleto-pesaje-salida.empresa.razon-social"
                      ) as string,
                      fontSize: 10,
                    },
                    {
                      text: ":",
                      fontSize: 10,
                    },
                    {
                      text: empresa.razonSocial?.toUpperCase() ?? "",
                      fontSize: 10,
                    },
                  ],
                  [
                    {
                      text: i18n.t(
                        "boleto-pesaje-salida.empresa.giro"
                      ) as string,
                      fontSize: 10,
                    },
                    {
                      text: ":",
                      fontSize: 10,
                    },
                    {
                      text: empresa.giro?.toUpperCase() ?? "",
                      fontSize: 10,
                    },
                  ],
                  [
                    {
                      text: i18n.t(
                        "boleto-pesaje-salida.empresa.rut"
                      ) as string,
                      fontSize: 10,
                    },
                    {
                      text: ":",
                      fontSize: 10,
                    },
                    {
                      text: empresa.rut?.toRUT() ?? "",
                      fontSize: 10,
                    },
                  ],
                  [
                    {
                      text: i18n.t(
                        "boleto-pesaje-salida.empresa.direccion"
                      ) as string,
                      fontSize: 10,
                    },
                    {
                      text: ":",
                      fontSize: 10,
                    },
                    {
                      text: empresa.direccion?.toUpperCase() ?? "",
                      fontSize: 10,
                    },
                  ],
                  [
                    {
                      text: i18n.t(
                        "boleto-pesaje-salida.empresa.ciudad"
                      ) as string,
                      fontSize: 10,
                    },
                    {
                      text: ":",
                      fontSize: 10,
                    },
                    {
                      text: empresa.ciudad?.toUpperCase() ?? "",
                      fontSize: 10,
                    },
                  ],
                  [
                    {
                      text: i18n.t(
                        "boleto-pesaje-salida.empresa.telefono"
                      ) as string,
                      fontSize: 10,
                    },
                    {
                      text: ":",
                      fontSize: 10,
                    },
                    {
                      text: empresa.telefono?.toUpperCase() ?? "",
                      fontSize: 10,
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
            {
              margin: [0, 8, 0, 0],
              alignment: "center",
              table: {
                headerRows: 0,
                widths: ["*"],
                body: [
                  [
                    {
                      fit: 80,
                      qr: qr,
                    },
                  ],
                  [
                    {
                      text: i18n.t(
                        "boleto-pesaje-salida.qr.validacion"
                      ) as string,
                      fontSize: 10,
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
          ],
        },
        {
          margin: [0, 8, 0, 0],
          table: {
            headerRows: 1,
            widths: ["*"],
            body: [[""], [""]],
          },
          layout: "headerLineOnly",
        },
        {
          margin: [0, 8, 0, 0],
          table: {
            headerRows: 0,
            widths: ["15%", 1, "35%", "15%", 1, "35%"],
            body: [
              [
                {
                  text: i18n.t("boleto-pesaje-salida.tabla.labels.0") as string,
                  fontSize: 10,
                },
                {
                  text: ":",
                  fontSize: 10,
                },
                {
                  text: pesaje.id ?? "",
                  fontSize: 10,
                },
                "",
                "",
                "",
              ],
              [
                {
                  text: i18n.t("boleto-pesaje-salida.tabla.labels.1") as string,
                  fontSize: 10,
                },
                {
                  text: ":",
                  fontSize: 10,
                },
                {
                  text: pesaje.vehiculo?.patente?.toUpperCase() ?? "",
                  fontSize: 10,
                },
                {
                  text: i18n.t("boleto-pesaje-salida.tabla.labels.2") as string,
                  fontSize: 10,
                },
                {
                  text: ":",
                  fontSize: 10,
                },
                {
                  text: pesaje.vehiculo?.tipo?.toUpperCase() ?? "",
                  fontSize: 10,
                },
              ],
              [
                {
                  text: i18n.t("boleto-pesaje-salida.tabla.labels.3") as string,
                  fontSize: 10,
                },
                {
                  text: ":",
                  fontSize: 10,
                },
                {
                  text: pesaje.cliente?.nombre?.toUpperCase() ?? "",
                  fontSize: 10,
                },
                "",
                "",
                "",
              ],
              [
                {
                  text: i18n.t("boleto-pesaje-salida.tabla.labels.4") as string,
                  fontSize: 10,
                },
                {
                  text: ":",
                  fontSize: 10,
                },
                {
                  text: pesaje.desecho?.nombre?.toUpperCase() ?? "",
                  fontSize: 10,
                },
                {
                  text: i18n.t("boleto-pesaje-salida.tabla.labels.5") as string,
                  fontSize: 10,
                },
                {
                  text: ":",
                  fontSize: 10,
                },
                {
                  text: pesaje.ruta?.nombre?.toUpperCase() ?? "",
                  fontSize: 10,
                },
              ],
              [
                {
                  text: i18n.t("boleto-pesaje-salida.tabla.labels.6") as string,
                  fontSize: 10,
                },
                {
                  text: ":",
                  fontSize: 10,
                },
                {
                  text: pesaje.entrada?.fecha
                    ? new Date(pesaje.entrada?.fecha).format("DD-MM-YYYY")
                    : "",
                  fontSize: 10,
                },
                {
                  text: i18n.t("boleto-pesaje-salida.tabla.labels.7") as string,
                  fontSize: 10,
                },
                {
                  text: ":",
                  fontSize: 10,
                },
                {
                  text: pesaje.entrada?.fecha
                    ? new Date(pesaje.entrada.fecha).format("HH:mm:ss")
                    : "",
                  fontSize: 10,
                },
              ],
              [
                {
                  text: i18n.t("boleto-pesaje-salida.tabla.labels.8") as string,
                  fontSize: 10,
                },
                {
                  text: ":",
                  fontSize: 10,
                },
                {
                  text: pesaje.entrada?.chofer?.nombre?.toUpperCase() ?? "",
                  fontSize: 10,
                },
                "",
                "",
                "",
              ],
              [
                {
                  text: i18n.t("boleto-pesaje-salida.tabla.labels.9") as string,
                  fontSize: 10,
                },
                {
                  text: ":",
                  fontSize: 10,
                },
                {
                  text: pesaje.salida?.observacion?.toUpperCase() ?? "",
                  fontSize: 10,
                },
                "",
                "",
                "",
              ],
              [
                {
                  text: i18n.t(
                    "boleto-pesaje-salida.tabla.labels.10"
                  ) as string,
                  fontSize: 10,
                },
                {
                  text: ":",
                  fontSize: 10,
                },
                {
                  text: pesaje.salida?.fecha
                    ? new Date(pesaje.salida.fecha).format("DD-MM-YYYY")
                    : "",
                  fontSize: 10,
                },
                {
                  text: i18n.t(
                    "boleto-pesaje-salida.tabla.labels.11"
                  ) as string,
                  fontSize: 10,
                },
                {
                  text: ":",
                  fontSize: 10,
                },
                {
                  text: pesaje.salida.fecha
                    ? new Date(pesaje.salida.fecha).format("HH:mm:ss")
                    : "",
                  fontSize: 10,
                },
              ],
              [
                {
                  text: i18n.t(
                    "boleto-pesaje-salida.tabla.labels.12"
                  ) as string,
                  fontSize: 10,
                },
                {
                  text: ":",
                  fontSize: 10,
                },
                {
                  text: pesaje.entrada?.chofer?.nombre?.toUpperCase() ?? "",
                  fontSize: 10,
                },
                "",
                "",
                "",
              ],
              [
                {
                  text: i18n.t(
                    "boleto-pesaje-salida.tabla.labels.13"
                  ) as string,
                  fontSize: 10,
                },
                {
                  text: ":",
                  fontSize: 10,
                },
                {
                  text: pesaje.entrada?.observacion?.toUpperCase() ?? "",
                  fontSize: 10,
                },
                "",
                "",
                "",
              ],
              [
                {
                  text: i18n.t(
                    "boleto-pesaje-salida.tabla.labels.14"
                  ) as string,
                  fontSize: 10,
                },
                {
                  text: ":",
                  fontSize: 10,
                },
                {
                  text: pesaje.servicio?.nombre?.toUpperCase() ?? "",
                  fontSize: 10,
                },
                "",
                "",
                "",
              ],
              [
                {
                  text: i18n.t(
                    "boleto-pesaje-salida.tabla.labels.15"
                  ) as string,
                  fontSize: 10,
                },
                {
                  text: ":",
                  fontSize: 10,
                },
                {
                  text: m3 ?? "",
                  fontSize: 10,
                },
                "",
                "",
                "",
              ],
            ],
          },
          layout: "noBorders",
        },
        {
          margin: [0, 8, 0, 0],
          table: {
            headerRows: 1,
            widths: ["*"],
            body: [[""], [""]],
          },
          layout: "headerLineOnly",
        },
        {
          margin: [0, 8, 0, 0],
          table: {
            headerRows: 0,
            widths: ["*", 1, "*", "*", 1, "*", "*", 1, "*"],
            body: [
              [
                {
                  text: i18n.t(
                    "boleto-pesaje-salida.tabla.labels.16"
                  ) as string,
                  fontSize: 12,
                },
                {
                  text: ":",
                  fontSize: 12,
                },
                {
                  text: pesaje.entrada?.peso?.toCurrency() ?? "0",
                  fontSize: 12,
                },
                {
                  text: i18n.t(
                    "boleto-pesaje-salida.tabla.labels.17"
                  ) as string,
                  fontSize: 12,
                },
                {
                  text: ":",
                  fontSize: 12,
                },
                {
                  text: pesaje.salida?.peso?.toCurrency() ?? "0",
                  fontSize: 12,
                },
                {
                  text: i18n.t(
                    "boleto-pesaje-salida.tabla.labels.18"
                  ) as string,
                  fontSize: 12,
                },
                {
                  text: ":",
                  fontSize: 12,
                },
                {
                  text: pesoNeto.toCurrency(),
                  fontSize: 12,
                },
              ],
            ],
          },
          layout: "noBorders",
        },
        {
          margin: [0, 8, 0, 0],
          table: {
            headerRows: 1,
            widths: ["*"],
            body: [[""], [""]],
          },
          layout: "headerLineOnly",
        },
        {
          margin: [0, 8, 0, 0],
          table: {
            headerRows: 0,
            widths: ["15%", 1, "35%"],
            body: [
              [
                {
                  text: i18n.t(
                    "boleto-pesaje-salida.tabla.labels.19"
                  ) as string,
                  fontSize: 10,
                },
                {
                  text: ":",
                  fontSize: 10,
                },
                {
                  text: i18n.t("boleto-pesaje-salida.bascula") as string,
                  fontSize: 10,
                },
              ],
              [
                {
                  text: i18n.t(
                    "boleto-pesaje-salida.tabla.labels.20"
                  ) as string,
                  fontSize: 10,
                },
                {
                  text: ":",
                  fontSize: 10,
                },
                {
                  text: pesaje.salida?.peso?.toCurrency() ?? "0",
                  fontSize: 10,
                },
              ],
            ],
          },
          layout: "noBorders",
        },
      ],
    };
  };

  return {
    distanceBetween,
    speedCalculator,
    msToTime,
    $allowedDatesBeforeToday,
    $printExitTicket,
  };
}
