// composition-api
import { computed, ref } from "vue";
// vuex
import store from "@/store";
// tipos
import {
  Vehiculo,
  Vehiculos,
} from "@/typings/store/plugins/easyFirestore/vehiculos";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useVehiculos(config?: { ordenarArray?: boolean }) {
  const ordenar = ref(config?.ordenarArray ?? false);

  const setOrdenarArray = (value: boolean) => {
    ordenar.value = value;
  };

  const get = computed(() => {
    return store.getters["vehiculos/get"] as Vehiculos;
  });

  const sort = (array: Vehiculo[]) => {
    array.sort((a, b) => {
      if (a.patente < b.patente) return -1;
      if (a.patente > b.patente) return 1;
      return 0;
    });
    return array;
  };

  const getArray = computed(() => {
    let vehiculos = store.getters["vehiculos/getArray"] as Vehiculo[];
    if (ordenar.value) {
      vehiculos = sort(vehiculos);
    }
    return vehiculos;
  });

  const getArrayHabilitados = computed(() => {
    return getArray.value.filter(
      (vehiculo) => vehiculo.estado === "habilitado"
    );
  });

  const getById = (id: string): Vehiculo | null => {
    if (!id) return null;
    return get.value[id] ?? null;
  };

  return { get, getArray, getArrayHabilitados, setOrdenarArray, getById };
}
