var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-list',{staticClass:"pa-0 ma-0",attrs:{"dense":""}},[_c('v-list-item-group',{staticClass:"overflow-auto list-group",model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},_vm._l((_vm.rutas),function(ruta,index){return _c('v-list-item',{key:index,staticClass:"list-item pa-0 ml-7 mr-3 my-0",attrs:{"to":ruta.to,"value":ruta.to?.name,"active-class":_vm.miniVariant ? '' : 'selected-item'}},[[_c('v-list-item-action',{class:{
            'pl-4': !_vm.miniVariant,
            'py-1 ma-0': true,
          }},[_c('v-card',{class:`icon-card pa-3 ${
              _vm.active(ruta) ? 'gradient-primary' : 'white'
            }`,attrs:{"width":"39px","height":"39px"}},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[(ruta.icon.startsWith('mdi'))?_c('v-icon',{attrs:{"color":_vm.active(ruta) ? '#FFFFFF' : 'darker',"size":"16px"}},[_vm._v(" "+_vm._s(ruta.icon)+" ")]):_c('svg-icon',{attrs:{"width":"16px","height":"16px","src":require(`@/assets/svg/sistema/modulos/${ruta.icon}.svg`),"color":_vm.active(ruta) ? '#FFFFFF' : '#31343A'}})],1)],1)],1)],1),(!_vm.miniVariant)?_c('v-list-item-content',{staticClass:"pl-7"},[_c('v-list-item-title',{class:{
              'white--text': _vm.active(ruta),
              'darker--text ': !_vm.active(ruta),
              'font-weight-bold': _vm.active(ruta),
              'text-capitalize metropolis': true,
            }},[_vm._v(" "+_vm._s(ruta.text)+" ")])],1):_vm._e()]],2)}),1),_c('v-list-item',{staticClass:"item-logout ml-7 mr-3 mt-4 mb-20",on:{"click":_vm.onLogout}},[_c('v-list-item-action',{staticClass:"d-flex justify-center"},[_c('svg-icon',{attrs:{"name":"ag-logout","width":"30px","height":"30px","color":"darker"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-capitalize darker--text metropolis"},[_vm._v(" "+_vm._s(_vm.$t("nav-drawer.listas-rutas.logout.text"))+" ")])],1)],1),_c('v-list-item',{staticClass:"item-arrow ml-7 mr-3 ma-0",on:{"click":function($event){_vm.miniVariant = !_vm.miniVariant}}},[_c('v-list-item-action',{staticClass:"d-flex justify-center"},[_c('svg-icon',{attrs:{"name":_vm.miniVariant ? 'ag-arrow-expand' : 'ag-arrow-compress',"width":"26px","height":"26px","color":"darker"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-capitalize darker--text metropolis"},[_vm._v(" "+_vm._s(_vm.$t("nav-drawer.listas-rutas.contraer.text"))+" ")])],1)],1),_c('v-list-item',{staticClass:"ml-7 mr-3 mb-md-9 mb-16 mt-3"},[_c('v-list-item-title',{class:{
        'font-size-10': _vm.miniVariant,
        'font-size-12': !_vm.miniVariant,
        'metropolis text-center': true,
      }},[_vm._v(" "+_vm._s(_vm.$t("nav-drawer.listas-rutas.version.text", {number: _vm.version}))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }