import Vue from "vue";
import VueRouter from "vue-router";
//routes
import login from "./login";
import home from "./home";
import bascula from "./bascula";
import clientes from "./clientes";
import reportes from "./reportes";
import usuarios from "./usuarios";
import monitoreo from "./monitoreo";
import desechos from "./desechos";
import servicios from "./servicios";
import pesajes from "./pesajes";
import qr from "@/router/qr";
import terminosCondiciones from "@/router/terminosCondiciones";
import inicio from "@/router/inicio";
import barredores from "@/router/barredores";
import revista from "@/router/revista";
import transporte from "@/router/transporte";
import rastreadores from "@/router/rastreadores";
import planes from "@/router/planes";
import alertasBarredores from "@/router/alertasBarredores";

Vue.use(VueRouter);

const routes = [
  login,
  inicio,
  home,
  bascula,
  clientes,
  reportes,
  usuarios,
  monitoreo,
  desechos,
  servicios,
  pesajes,
  qr,
  terminosCondiciones,
  barredores,
  revista,
  transporte,
  rastreadores,
  planes,
  alertasBarredores,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

export default router;
