// Store
import store from "@/store";

// Router
import router from "@/router";

// Tipos
import {Ruta} from "@/typings/store/modules/rutas";

const mobile = window.innerWidth < 1264;

export default function routeGuard(): boolean {
  const permisos = store.getters["usuario/getPermisos"];
  const currentRoute = router.currentRoute.name ?? "login";
  const rutas = store.getters["usuario/getRutas"] as Ruta[];
  const rutasMovil = rutas.filter(_ruta => _ruta.mobile);
  if (permisos && permisos[currentRoute]) {
    if (mobile && rutasMovil.length > 0) {
      const ruta = rutas.find(_ruta => _ruta.id === currentRoute);
      if (ruta?.mobile) return true;
    } else {
      return true;
    }
  }
  const rutaReplace = mobile && rutasMovil.length > 0 ? rutasMovil[0] : rutas[0];
  if (rutaReplace?.to) {
    router.replace(rutaReplace.to);
    return false;
  }
  router.replace({name: "login"});
  return false;
}
