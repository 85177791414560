// composition-api
import { computed, ref } from "vue";
// vuex
import store from "@/store";
// tipos
import {
  Identificador,
  Identificadores,
} from "@/typings/store/plugins/easyFirestore/identificadores";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useIdentificadores(config?: { ordenarArray?: boolean }) {
  const ordenar = ref(config?.ordenarArray ?? false);

  const setOrdenarArray = (value: boolean) => {
    ordenar.value = value;
  };

  const get = computed(() => {
    return store.getters["identificadores/get"] as Identificadores;
  });

  const sort = (array: Identificador[]) => {
    array.sort((a, b) => {
      return a.nombre.localeCompare(b.nombre);
    });
    return array;
  };

  const getArray = computed(() => {
    let identificadores = store.getters[
      "identificadores/getArray"
    ] as Identificador[];
    if (ordenar.value) {
      identificadores = sort(identificadores);
    }
    return identificadores;
  });

  const getById = (id: string): Identificador | null => {
    if (!id) return null;
    return get.value[id] ?? null;
  };

  const getByEPC = (epc: string): Identificador | null => {
    if (!epc) return null;
    const res = getArray.value.find(
      (identificador) => identificador.epc === epc
    );
    return res ?? null;
  };

  return { get, getArray, setOrdenarArray, getById, getByEPC };
}
