var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0"},[_c('v-row',{staticClass:"metropolis text-body-1",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{class:`font-weight-bold pr-2 ${
        _vm.manual ? 'white--text' : 'black--text'
      }`,attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.$t("bascula.formulario.vehiculo.label"))+" ")]),_c('v-col',{staticClass:"px-2",attrs:{"cols":"3"}},[_c('v-combobox',{class:`rounded-input metropolis  ${
          _vm.manual ? 'border-input' : 'elevation-input'
        }`,attrs:{"dense":"","return-object":"","item-text":"patente","hide-details":"auto","placeholder":_vm.$t('bascula.formulario.vehiculo.placeholder'),"dark":_vm.manual,"solo":!_vm.manual,"items":_vm.arrayVehiculos,"outlined":_vm.manual,"error-messages":_vm.vehiculoErrors,"menu-props":{
          contentClass: 'rounded-input elevation-black',
          transition: 'slide-y-transition',
        }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{staticClass:"metropolis darker--text text-uppercase"},[_vm._v(" "+_vm._s(item.patente)+" ")])]}},{key:"selection",fn:function({ item }){return [_c('span',{class:`metropolis text-uppercase text-truncate ${
              _vm.manual ? 'white--text' : 'darker--text'
            }`},[_vm._v(" "+_vm._s(item.patente)+" ")])]}},{key:"append",fn:function(){return [_c('svg-icon',{attrs:{"name":"ionic-md-arrow-dropdown","color":"text","width":"12px","height":"12px"}})]},proxy:true}]),model:{value:(_vm.vehiculo),callback:function ($$v) {_vm.vehiculo=$$v},expression:"vehiculo"}})],1),_c('v-col',{class:`font-weight-bold px-2 ${
        _vm.manual ? 'white--text' : 'black--text'
      }`,attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.$t("bascula.formulario.desecho.label"))+" ")]),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"3"}},[_c('v-select',{class:`rounded-input metropolis  ${
          _vm.manual ? 'border-input' : 'elevation-input'
        }`,attrs:{"dense":"","return-object":"","item-text":"nombre","hide-details":"auto","placeholder":_vm.$t('bascula.formulario.desecho.placeholder'),"dark":_vm.manual,"solo":!_vm.manual,"items":_vm.arrayDesechos,"outlined":_vm.manual,"error-messages":_vm.desechoErrors,"menu-props":{
          contentClass: 'rounded-input elevation-black',
          transition: 'slide-y-transition',
        }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{staticClass:"metropolis darker--text text-capitalize"},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"selection",fn:function({ item }){return [_c('span',{class:`metropolis text-capitalize text-truncate ${
              _vm.manual ? 'white--text' : 'darker--text'
            }`},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"append",fn:function(){return [_c('svg-icon',{attrs:{"name":"ionic-md-arrow-dropdown","color":"text","width":"12px","height":"12px"}})]},proxy:true}]),model:{value:(_vm.desecho),callback:function ($$v) {_vm.desecho=$$v},expression:"desecho"}})],1)],1),_c('v-row',{staticClass:"metropolis pt-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{class:`font-weight-bold pr-2 ${
        _vm.manual ? 'white--text' : 'black--text'
      }`,attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.$t("bascula.formulario.capacidad.label"))+" ")]),_c('v-col',{staticClass:"px-2",attrs:{"cols":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['#########']),expression:"['#########']"}],class:`rounded-input metropolis  ${
          _vm.manual ? 'border-input' : 'elevation-input'
        }`,attrs:{"dense":"","hide-details":"auto","placeholder":_vm.$t('bascula.formulario.capacidad.placeholder'),"dark":_vm.manual,"solo":!_vm.manual,"outlined":_vm.manual,"error-messages":_vm.capacidadErrors},model:{value:(_vm.capacidad),callback:function ($$v) {_vm.capacidad=_vm._n($$v)},expression:"capacidad"}})],1),_c('v-col',{class:`font-weight-bold px-2 ${
        _vm.manual ? 'white--text' : 'black--text'
      }`,attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.$t("bascula.formulario.cobro.label"))+" ")]),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['#########']),expression:"['#########']"}],class:`rounded-input metropolis  ${
          _vm.manual ? 'border-input' : 'elevation-input'
        }`,attrs:{"dense":"","hide-details":"","placeholder":_vm.$t('bascula.formulario.cobro.placeholder'),"dark":_vm.manual,"solo":!_vm.manual,"outlined":_vm.manual},model:{value:(_vm.cobro),callback:function ($$v) {_vm.cobro=_vm._n($$v)},expression:"cobro"}})],1)],1),_c('v-row',{staticClass:"metropolis pt-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{class:`font-weight-bold pr-2 ${
        _vm.manual ? 'white--text' : 'black--text'
      }`,attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.$t("bascula.formulario.servicio.label"))+" ")]),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"9"}},[_c('v-select',{class:`rounded-input metropolis ${
          _vm.manual ? 'border-input' : 'elevation-input'
        }`,attrs:{"dense":"","return-object":"","item-text":"nombre","hide-details":"auto","placeholder":_vm.$t('bascula.formulario.servicio.placeholder'),"dark":_vm.manual,"solo":!_vm.manual,"outlined":_vm.manual,"items":_vm.arrayServicios,"error-messages":_vm.servicioErrors,"menu-props":{
          contentClass: 'rounded-input elevation-black',
          transition: 'slide-y-transition',
        }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{staticClass:"metropolis darker--text text-capitalize"},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"selection",fn:function({ item }){return [_c('span',{class:`metropolis text-capitalize text-truncate ${
              _vm.manual ? 'white--text' : 'darker--text'
            }`},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"append",fn:function(){return [_c('svg-icon',{attrs:{"name":"ionic-md-arrow-dropdown","color":"text","width":"12px","height":"12px"}})]},proxy:true}]),model:{value:(_vm.servicio),callback:function ($$v) {_vm.servicio=$$v},expression:"servicio"}})],1)],1),_c('v-row',{staticClass:"metropolis pt-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{class:`font-weight-bold pr-2 ${
        _vm.manual ? 'white--text' : 'black--text'
      }`,attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.$t("bascula.formulario.cliente.label"))+" ")]),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"9"}},[_c('v-combobox',{class:`rounded-input metropolis  ${
          _vm.manual ? 'border-input' : 'elevation-input'
        }`,attrs:{"dense":"","return-object":"","item-text":"nombre","hide-details":"auto","placeholder":_vm.$t('bascula.formulario.cliente.placeholder'),"dark":_vm.manual,"solo":!_vm.manual,"items":_vm.arrayClientes,"outlined":_vm.manual,"error-messages":_vm.clienteErrors,"menu-props":{
          contentClass: 'rounded-input elevation-black',
          transition: 'slide-y-transition',
        }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{staticClass:"metropolis darker--text text-capitalize"},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"selection",fn:function({ item }){return [_c('span',{class:`metropolis text-capitalize text-truncate ${
              _vm.manual ? 'white--text' : 'darker--text'
            }`},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"append",fn:function(){return [_c('svg-icon',{attrs:{"name":"ionic-md-arrow-dropdown","color":"text","width":"12px","height":"12px"}})]},proxy:true}]),model:{value:(_vm.cliente),callback:function ($$v) {_vm.cliente=$$v},expression:"cliente"}})],1)],1),_c('v-row',{staticClass:"metropolis pt-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{class:`font-weight-bold pr-2 ${
        _vm.manual ? 'white--text' : 'black--text'
      }`,attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.$t("bascula.formulario.ruta.label"))+" ")]),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"9"}},[_c('v-combobox',{class:`rounded-input metropolis  ${
          _vm.manual ? 'border-input' : 'elevation-input'
        }`,attrs:{"dense":"","return-object":"","item-text":"nombre","hide-details":"auto","placeholder":_vm.$t('bascula.formulario.ruta.placeholder'),"items":_vm.arrayRutasPesaje,"dark":_vm.manual,"solo":!_vm.manual,"outlined":_vm.manual,"error-messages":_vm.rutaErrors,"menu-props":{
          contentClass: 'rounded-input elevation-black',
          transition: 'slide-y-transition',
        }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{staticClass:"metropolis darker--text text-capitalize"},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"selection",fn:function({ item }){return [_c('span',{class:`metropolis text-capitalize text-truncate ${
              _vm.manual ? 'white--text' : 'darker--text'
            }`},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"append",fn:function(){return [_c('svg-icon',{attrs:{"name":"ionic-md-arrow-dropdown","color":"text","width":"12px","height":"12px"}})]},proxy:true}]),model:{value:(_vm.ruta),callback:function ($$v) {_vm.ruta=$$v},expression:"ruta"}})],1)],1),(!_vm.manual)?_c('v-row',{staticClass:"pt-2",attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"rounded-input",attrs:{"depressed":"","height":"48px","color":"#FFF0B7"},on:{"click":_vm.onClickLimpiar}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('svg-icon',{attrs:{"name":"agr-sync-alt","color":"yellow"}})],1),_c('v-col',{staticClass:"pl-2 metropolis font-weight-bold yellow--text text-initial text-body-1",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t("bascula.formulario.boton-limpiar.text"))+" ")])],1)],1)],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"rounded-input",attrs:{"color":"disabled","depressed":"","height":"48px"},on:{"click":_vm.onClickManual}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('svg-icon',{attrs:{"name":"ag-document-add","color":"dialogDark"}})],1),_c('v-col',{staticClass:"pl-2 metropolis font-weight-bold text-initial dialogDark--text text-body-1",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t("bascula.formulario.boton-manual.text"))+" ")])],1)],1)],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"auto"}},[_c('v-btn',{class:`rounded-input ${
          !_vm.fijado
            ? 'button-inactive button-disabled'
            : _vm.guardando
            ? 'button-disabled'
            : ''
        }`,attrs:{"depressed":"","height":"48px","color":"primary-light"},on:{"click":_vm.onClickGuardar}},[(_vm.guardando)?_c('svg-icon',{attrs:{"name":"three-dots","color":"primary"}}):_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('svg-icon',{attrs:{"name":"ag-save","color":"primary"}})],1),_c('v-col',{staticClass:"pl-2 metropolis font-weight-bold text-initial primary--text text-body-1",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t("bascula.formulario.boton-guardar.text"))+" ")])],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }