import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import es from "vuetify/src/locale/es";

import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { es },
    current: "es",
  },
  theme: {
    themes: {
      light: {
        text: "#9BABB1",
        error: "#CE3636",
        accent: "#6CA330",
        darker: "#31343A",
        yellow: "#F5C000",
        purple: "#4B31E8",
        dialog: "#3B4E56",
        primary: "#008A1C",
        success: "#008A1C",
        warning: "#F5C000",
        overlay: "#2D3D43",
        tertiary: "#EAFFF3",
        disabled: "#CFDCE2",
        secondary: "#52D6FF",
        background: "#F8F9FA",
        dialogDark: "#283E44",
        "primary-light": "#BBEBDA",
        "disabled-light":"#354F57"
      },
    },
    options: {
      customProperties: true,
    },
  },
});
