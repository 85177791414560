//tipos
import { ActionTree, GetterTree } from "vuex";
import { State } from "@/typings/store";
import { StateDesechos } from "@/typings/store/plugins/easyFirestore/desechos";

const getters: GetterTree<StateDesechos, State> = {
  get(state) {
    return state.all;
  },
  getArray(state) {
    return Object.values(state.all);
  },
};

const actions: ActionTree<StateDesechos, State> = {};

const desechos = {
  firestorePath: "{env}/v1/empresas/{idEmpresa}/desechos",
  firestoreRefType: "collection",
  moduleName: "desechos",
  statePropName: "all",
  namespaced: true,

  state: {},
  getters,
  mutations: {},
  actions,
};

export default desechos;
