import Firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

function initFirebase(): Promise<void> {
  Firebase.initializeApp({
    apiKey: "AIzaSyDrGxdu-mm-9UnOAKprRq8TyE_WaDadREQ",
    authDomain: "rt-weight-control.firebaseapp.com",
    databaseURL: "https://rt-weight-control.firebaseio.com",
    projectId: "rt-weight-control",
    storageBucket: "rt-weight-control.appspot.com",
    messagingSenderId: "655924148982",
    appId: "1:655924148982:web:366d239cc930bb3be80ad7",
    measurementId: "G-3MY2C4D35R",
  });
  return new Promise((resolve, reject) => {
    Firebase.firestore()
      .enablePersistence()
      .then(resolve)
      .catch((err) => {
        if (err.code === "failed-precondition") {
          reject(err);
        } else if (err.code === "unimplemented") {
          reject(err);
        }
        reject(err);
      });
  });
}

export { Firebase, initFirebase };
