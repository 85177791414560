//tipos
import { GetterTree, ActionTree, MutationTree } from "vuex";
import { State } from "@/typings/store/index";
import {
  Barredor,
  Barredores,
  StateBarredores,
} from "@/typings/store/plugins/easyFirestore/barredores";

const state: StateBarredores = {
  all: {},
};

const getters: GetterTree<StateBarredores, State> = {
  get(state, getters) {
    return (getters["getArray"] as Array<Barredor>).reduce((docs, doc) => {
      if (!doc?.id) {
        console.error("doc.id == undefined => ", doc);
        return docs;
      }
      docs[doc.id] = doc;
      return docs;
    }, {} as Barredores);
  },
  getArray(state) {
    return Object.values(state.all).map((doc) => {
      return JSON.parse(JSON.stringify(doc)) as Barredores;
    });
  },
};

const mutations: MutationTree<StateBarredores> = {};

const actions: ActionTree<StateBarredores, State> = {};

const barredores = {
  firestorePath: "{env}/v1/empresas/{idEmpresa}/barredores",
  firestoreRefType: "collection",
  moduleName: "barredores",
  statePropName: "all",
  namespaced: true,

  state,
  getters,
  mutations,
  actions,
};

export default barredores;
