// Vuex
import { Store } from "vuex";

// Helpers
import { log } from "@/helpers/env";

// Modulos
import modulos from "./modulos";

// Tipos
import { State } from "@/typings/store";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import {
  PathVariables,
  Clauses,
} from "@/typings/store/plugins/easyFirestore/iniciar/abrirCanalesBD";

const mobile = window.innerWidth < 1264;

const abrirCanalesBD = async (
  store: Store<State>,
  usuario: Usuario
): Promise<void> => {
  const moduloAbierto: { [key: string]: boolean } = {};
  const promesas = modulos(usuario.tipo).map((modulo) => {
    if (moduloAbierto[modulo.nombre]) {
      return null;
    }
    const valido = modulo.permisos.some((permiso) => {
      return usuario.permisos[permiso];
    });
    if (mobile && !modulo.mobile) return null;
    if (valido || modulo.permisos.length === 0) {
      const pathVariables: PathVariables = {};
      const clauses: Clauses = {};
      modulo.parametros.forEach((parametro) => {
        if (!parametro.name || !parametro.value) {
          return;
        }
        if (!parametro.user) {
          pathVariables[parametro.name] = parametro.value;
          return;
        }
        let valor = usuario as never;
        parametro.value.split(".").forEach((key) => {
          if (!valor || !valor[key]) {
            return;
          }
          valor = valor[key];
        });
        if (!valor) {
          return;
        }
        pathVariables[parametro.name] = valor;
      });
      modulo.where.forEach((item) => {
        if (!item.name || !item.comparacion || !item.value) {
          return;
        }
        if (!item.user) {
          if (!clauses.where) {
            clauses.where = [];
          }
          clauses.where.push([item.name, item.comparacion, item.value]);
          return;
        }
        let valor = usuario as never;
        item.value.split(".").forEach((key) => {
          if (!valor || !valor[key]) {
            return;
          }
          valor = valor[key];
        });
        if (!valor) {
          return;
        }
        if (!clauses.where) {
          clauses.where = [];
        }
        clauses.where.push([item.name, item.comparacion, valor]);
      });
      moduloAbierto[modulo.nombre] = true;
      return new Promise<void>((res) => {
        if (log) console.time(`abrirCanalesBD/${modulo.nombre}`);
        store
          .dispatch(modulo.nombre + "/openDBChannel", {
            pathVariables,
            clauses,
          })
          .catch((error) => {
            if (log) {
              console.error(`error modulo: ${modulo.nombre} => `, error);
            }
          })
          .then(() => {
            if (log) console.timeEnd(`abrirCanalesBD/${modulo.nombre}`);
            res();
          });
      });
    }
    return null;
  });
  await Promise.all(promesas);
};

export default abrirCanalesBD;
