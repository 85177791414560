var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column fill-height"},[_c('v-row',{staticClass:"flex-grow-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center text-h6 metropolis font-weight-bold primary--text"},[_vm._v(" "+_vm._s(_vm.titulo)+" ")])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',{staticClass:"pt-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"metropolis text-body-1 darker--text",attrs:{"cols":"11"}},[_vm._v(" "+_vm._s(_vm.t("transporte.tab.identificadores.formulario.nombre.label"))+" ")]),_c('v-spacer'),_c('v-col',{staticClass:"pa-0 error--text metropolis",attrs:{"cols":"auto"}},[_vm._v(" * ")])],1)],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"9"}},[_c('v-text-field',{staticClass:"rounded-input elevation-input-dense metropolis font-weight-bold",attrs:{"solo":"","dense":"","hide-details":"auto","placeholder":_vm.t(
                'transporte.tab.identificadores.formulario.nombre.placeholder'
              ),"error-messages":_vm.erroresNombre},on:{"focus":function($event){return _vm.v$.nombre.$reset()},"blur":function($event){return _vm.v$.nombre.$touch()}},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}})],1)],1),_c('v-row',{staticClass:"pt-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"metropolis text-body-1 darker--text",attrs:{"cols":"11"}},[_vm._v(" "+_vm._s(_vm.t("transporte.tab.identificadores.formulario.epc.label"))+" ")]),_c('v-spacer'),_c('v-col',{staticClass:"pa-0 error--text metropolis",attrs:{"cols":"auto"}},[_vm._v(" * ")])],1)],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"9"}},[_c('v-text-field',{staticClass:"rounded-input elevation-input-dense metropolis font-weight-bold",attrs:{"solo":"","dense":"","hide-details":"auto","placeholder":_vm.t('transporte.tab.identificadores.formulario.epc.placeholder'),"error-messages":_vm.erroresEPC},on:{"focus":function($event){return _vm.v$.epc.$reset()},"input":function($event){return _vm.v$.epc.$touch()},"blur":function($event){return _vm.v$.epc.$touch()}},model:{value:(_vm.epc),callback:function ($$v) {_vm.epc=$$v},expression:"epc"}})],1)],1),_c('v-row',{staticClass:"pt-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"metropolis text-body-1 darker--text",attrs:{"cols":"11"}},[_vm._v(" "+_vm._s(_vm.t("transporte.tab.identificadores.formulario.vehiculo.label"))+" ")]),_c('v-spacer'),_c('v-col',{staticClass:"pa-0 error--text metropolis",attrs:{"cols":"auto"}},[_vm._v(" * ")])],1)],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"9"}},[_c('v-select',{staticClass:"rounded-input elevation-input-dense metropolis font-weight-bold",attrs:{"solo":"","dense":"","hide-details":"auto","placeholder":_vm.t(
                'transporte.tab.identificadores.formulario.vehiculo.placeholder'
              ),"items":_vm.arrayVehiculos,"return-object":"","item-value":"id","error-messages":_vm.erroresVehiculo,"menu-props":{
              contentClass: 'rounded-input elevation-black',
              transition: 'slide-y-transition',
            }},on:{"focus":function($event){return _vm.v$.vehiculo.$reset()},"blur":function($event){return _vm.v$.vehiculo.$touch()}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{staticClass:"metropolis darker--text text-body-1 font-weight-bold text-uppercase"},[_vm._v(" "+_vm._s(item.patente)+" ")])]}},{key:"selection",fn:function({ item }){return [_c('span',{staticClass:"metropolis darker--text text-body-1 font-weight-bold text-uppercase"},[_vm._v(" "+_vm._s(item.patente)+" ")])]}},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-chevron-down")])]},proxy:true}]),model:{value:(_vm.vehiculo),callback:function ($$v) {_vm.vehiculo=$$v},expression:"vehiculo"}})],1)],1)],1)],1),_c('v-row',{staticClass:"flex-grow-0 pt-2",attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-btn',{class:`rounded-input elevation-primary gradient-primary ${
          _vm.cargando ? 'button-disabled' : ''
        }`,attrs:{"large":"","block":"","height":"48px"},on:{"click":_vm.guardar}},[(_vm.cargando)?_c('svg-icon',{attrs:{"name":"three-dots"}}):_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('svg-icon',{attrs:{"name":_vm.identificador ? 'ag-save' : 'ag-add',"color":"white"}})],1),_c('v-col',{staticClass:"pl-2 white--text text-initial metropolis font-weight-bold text-body-1",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.textoBoton)+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }