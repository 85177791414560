// composition-api
import { computed, ref } from "vue";
// vuex
import store from "@/store";
// tipos
import {
  Chofer,
  Choferes,
} from "@/typings/store/plugins/easyFirestore/choferes";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useChoferes(config?: { ordenarArray?: boolean }) {
  const ordenar = ref(config?.ordenarArray ?? false);

  const setOrdenarArray = (value: boolean) => {
    ordenar.value = value;
  };

  const get = computed(() => {
    return store.getters["choferes/get"] as Choferes;
  });

  const sort = (array: Chofer[]) => {
    array.sort((a, b) => {
      return a.nombre.localeCompare(b.nombre);
    });
    return array;
  };

  const getArray = computed(() => {
    let choferes = store.getters["choferes/getArray"] as Chofer[];
    if (ordenar.value) {
      choferes = sort(choferes);
    }
    return choferes;
  });

  const getArrayHabilitados = computed(() => {
    return getArray.value.filter((chofer) => chofer.estado === "habilitado");
  });

  return { get, getArray, getArrayHabilitados, setOrdenarArray };
}
