import { Store } from "vuex";
import { State } from "@/typings/store";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";

const iniciarProcesos = async (
  store: Store<State>,
  usuario: Usuario
): Promise<void> => {
  if (usuario.permisos["barredores"]) {
    await store.dispatch("pingsRastreador/limpiarCache");
  }
  await store.dispatch("sw/iniciar");
};

export default iniciarProcesos;
