// composition-api
import { computed } from "vue";
// vuex
import store from "@/store";
// compresor imagen
import Compressor from "compressorjs";
// tipos
import {
  Archivo,
  Archivos,
  BlobArchivo,
} from "@/typings/store/plugins/easyFirestore/archivos";

interface OptionsImageToWebp {
  quality: number;
  maxWidth?: number;
  maxHeight?: number;
}

interface Metadata {
  ref: string;
  nombre: string;
}

const getArchivos = computed<Archivos>(() => {
  return store.getters["archivos/get"] as Archivos;
});

const getArrayArchivos = computed<Array<Archivo>>(() => {
  return store.getters["archivos/getArray"] as Archivo[];
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useArchivos() {
  const drop = (id: string): Promise<string> => {
    return store.dispatch("archivos/delete", id);
  };

  const setBlob = (data: BlobArchivo): Promise<string> => {
    return store.dispatch("archivos/setBlob", data);
  };

  const getById = (id: string): Archivo | null => {
    if (!id) return null;
    return getArchivos.value[id] ?? null;
  };

  const imageToWebp = (
    file: File | Blob,
    options?: OptionsImageToWebp
  ): Promise<File | Blob | null> => {
    return new Promise<File | Blob | null>((resolve) => {
      try {
        new Compressor(file, {
          quality: options?.quality ?? 0.85,
          mimeType: "image/webp",
          maxWidth: options?.maxWidth ?? 1920,
          maxHeight: options?.maxHeight ?? 1024,
          async success(result) {
            resolve(result);
          },
          error(err) {
            console.error(err.message);
            resolve(null);
          },
        });
      } catch (err) {
        console.error(err);
        resolve(null);
      }
    });
  };

  const dataUrlToBlob = async (dataUrl: string): Promise<Blob | null> => {
    const res = await fetch(dataUrl);
    if (!res) return null;
    const blob = await res.blob();
    if (!blob) return null;
    return blob;
  };

  const generarMetadata = (data: {
    coleccion: string;
    ext: string;
  }): Metadata => {
    const fecha = new Date();
    const nombre = `${fecha.valueOf()}.${data.ext}`;
    const fechaCarpeta = new Date(fecha);
    fechaCarpeta.setHours(0, 0, 0, 0);
    const nombreCarpeta = `${fechaCarpeta.valueOf()}`;
    return {
      nombre,
      ref: `${data.coleccion}/${nombreCarpeta}/${nombre}`,
    };
  };

  const dataUrlToBlobArchivo = async (data: {
    dataUrl: string;
    ext: string;
    coleccion: string;
    comprimir?: OptionsImageToWebp;
  }): Promise<BlobArchivo | null> => {
    if (!data.dataUrl || !data.ext || !data.coleccion) return null;
    const original = await dataUrlToBlob(data.dataUrl);
    if (!original) return null;
    let meta = generarMetadata({
      coleccion: data.coleccion,
      ext: data.ext,
    });
    if (data.comprimir) {
      const comprimido = await imageToWebp(original, data.comprimir);
      if (comprimido) {
        meta = generarMetadata({
          coleccion: data.coleccion,
          ext: "webp",
        });
        return {
          ref: meta.ref,
          nombre: meta.nombre,
          file: comprimido,
        };
      }
    }
    return {
      ref: meta.ref,
      nombre: meta.nombre,
      file: original,
    };
  };

  return {
    getArchivos,
    getArrayArchivos,
    drop,
    setBlob,
    getById,
    dataUrlToBlobArchivo,
  };
}
