var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mx-percent mt-lg-35 mt-16"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"font-size-lg-32 font-size-25 poppins font-weight-semi-bold text-center line-break dialogDark--text",domProps:{"innerHTML":_vm._s(_vm.$t('pagina.caracteristicas-pesaje.titulo'))}})],1),_c('v-row',{staticClass:"mt-lg-16 mt-8",attrs:{"no-gutters":""}},_vm._l((_vm.caracteristicas),function(caracteristica){return _c('v-col',{key:`caracteristica-${caracteristica.id}`,class:{
        'pr-6': caracteristica.id % 3 === 1 && !_vm.isMobile,
        'px-6': caracteristica.id % 3 === 2 && !_vm.isMobile,
        'pl-6': caracteristica.id % 3 === 0 && !_vm.isMobile,
        'pt-lg-9 pt-8':
          caracteristica.id / 3 > 1 || (caracteristica.id > 1 && _vm.isMobile),
      },attrs:{"cols":"12","lg":"4"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-img',{attrs:{"src":caracteristica.icono,"height":"68px","width":"68px","contain":""}})],1),_c('v-col',{staticClass:"pl-7"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"font-size-18 poppins font-weight-medium dialogDark--text"},[_vm._v(" "+_vm._s(caracteristica.titulo)+" ")])],1),_c('v-row',{staticClass:"mt-lg-2 mt-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"font-size-16 poppins font-weight-light dialogDark--text"},[_vm._v(" "+_vm._s(caracteristica.parrafo)+" ")])],1)],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }