var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"relative"},[_c('button',{staticClass:"button-map",attrs:{"title":_vm.titleHeatmap},on:{"click":_vm.mostrarHeatmap}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.showHeatmap ? "mdi-fire-off" : "mdi-fire")+" ")])],1),_c('GmapMap',{ref:"map",staticClass:"google-map",attrs:{"center":_vm.center,"zoom":_vm.zoom,"map-type-id":"terrain","options":{
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      styles: require('@/assets/json/gmap/MapStyles.json'),
    }},on:{"update:zoom":function($event){_vm.zoom=$event},"bounds_changed":_vm.boundsChangedHandler}},[_vm._l((_vm.barredores),function(barredor){return _c('RutaMapa',{key:barredor.id,attrs:{"zoom":_vm.zoom,"fecha":_vm.fecha,"barredor":barredor,"historico":_vm.historico,"seleccionados":_vm.seleccionados,"pings":_vm.agrupados[barredor.id],"horario":_vm.horarios[barredor.id]},on:{"click:seleccionar":_vm.seleccionar,"update:seleccionado":_vm.centrarMapa,"update:ultima-posicion":_vm.updateUltimaPosicion}})}),_vm._l((_vm.polylinesRutasPlanificadas),function(polyline){return _c('GmapPolyline',{key:`polyline-planificada-${polyline.id}`,attrs:{"path":polyline.path,"options":{
        strokeColor: polyline.color,
        strokeOpacity: 0.2,
        strokeWeight: 15,
        zIndex: 19,
      }},on:{"update:path":function($event){return _vm.$set(polyline, "path", $event)}}})}),(_vm.zoom > 15)?_vm._l((_vm.polylinesRutasPlanificadas),function(polyline){return _c('GmapMarker',{key:`marker-fin-plan-${polyline.id}`,attrs:{"position":polyline.path[polyline.path.length - 1],"icon":{
          url: require('@/assets/svg/icons/fas/fas-map-marker-alt-green.svg'),
          size: { width: 28, height: 28, f: 'px', b: 'px' },
          scaledSize: { width: 28, height: 28, f: 'px', b: 'px' },
        }}})}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }