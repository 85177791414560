var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-menu',{attrs:{"offset-y":"","min-width":_vm.width,"top":_vm.top,"transition":"slide-y-transition","content-class":`elevation-black rounded-input ${
    _vm.top ? 'rounded-b-0' : 'rounded-t-0'
  }`},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on, value }){return [_c('v-card',{ref:"activator-card",class:`elevation-black rounded-input ${
        value ? (_vm.top ? 'rounded-t-0' : 'rounded-b-0') : ''
      }`,attrs:{"color":"white"}},[_c('v-card-text',{staticClass:"px-4 py-2"},[_c('v-row',{staticClass:"metropolis text-body-1 black--text",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.$t(`${_vm.parent}.menu.ruta.label`))+" ")]),_c('v-col',{staticClass:"font-weight-bold text-capitalize"},[_vm._v(" "+_vm._s(_vm.nombre)+" ")]),(_vm.ruta)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":_vm.openMenu}},'v-btn',attrs,false),on),[_c('svg-icon',{staticClass:"elevation-primary",attrs:{"name":value
                    ? _vm.top
                      ? 'ionic-md-chevron-down'
                      : 'ionic-md-chevron-up'
                    : 'ionic-md-information-circle',"color":"primary","width":"20px","height":"20px"}})],1)],1):_vm._e()],1)],1)],1)]}}])},[(_vm.ruta)?_c('v-card',{class:`rounded-input ${
      _vm.top ? 'rounded-b-0 info-card-top' : 'rounded-t-0 info-card'
    }`,attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',{staticClass:"text-body-1 metropolis text--text",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.$t(`${_vm.parent}.menu.ruta.informacion.0`))+" ")]),_c('v-col',{staticClass:"font-weight-bold text-capitalize"},[_vm._v(" "+_vm._s(_vm.nombre)+" ")])],1),_c('v-row',{staticClass:"text-body-1 metropolis text--text",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.$t(`${_vm.parent}.menu.ruta.informacion.1`))+" ")]),_c('v-col',{staticClass:"font-weight-bold text-capitalize"},[_vm._v(" "+_vm._s(_vm.tipo)+" ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }