// composition-api
import {computed, reactive} from "vue";
// tipos
import {Rutas} from "@/typings/store/modules/rutas";
// composables
import {useI18n} from "./i18n";

const _i18n = useI18n();

const text = (key: string) => _i18n.t(`routes.names.${key}`) as string;

const rutas = reactive<Rutas>({
  ["home"]: {
    number: 0,
    tipos: [],
    id: "home",
    icon: "home",
    mobile: false,
    desktop: false,
    to: {name: "home"},
    text: text("home"),
  },
  ["bascula"]: {
    number: 1,
    id: "bascula",
    mobile: false,
    desktop: true,
    icon: "bascula",
    to: {name: "bascula"},
    text: text("bascula"),
    tipos: ["administrador", "bascula", "supervisor"],
  },
  ["monitoreo"]: {
    number: 2,
    mobile: false,
    desktop: true,
    id: "monitoreo",
    icon: "monitoreo",
    to: {name: "monitoreo"},
    text: text("monitoreo"),
    tipos: ["administrador", "bascula", "supervisor", "cliente"],
  },
  ["reportes"]: {
    number: 3,
    mobile: false,
    desktop: true,
    id: "reportes",
    icon: "reportes",
    to: {name: "reportes"},
    text: text("reportes"),
    tipos: ["administrador", "bascula", "supervisor", "cliente"],
  },
  ["pesajes"]: {
    number: 4,
    id: "pesajes",
    mobile: false,
    desktop: true,
    icon: "pesajes",
    to: {name: "pesajes"},
    text: text("pesajes"),
    tipos: ["administrador", "supervisor"],
  },
  ["usuarios"]: {
    number: 5,
    mobile: false,
    desktop: true,
    id: "usuarios",
    icon: "usuarios",
    to: {name: "usuarios"},
    text: text("usuarios"),
    tipos: ["administrador"],
  },
  ["clientes"]: {
    number: 6,
    mobile: false,
    desktop: true,
    id: "clientes",
    icon: "clientes",
    to: {name: "clientes"},
    text: text("clientes"),
    tipos: ["administrador", "supervisor"],
  },
  ["transporte"]: {
    number: 7,
    icon: "flota",
    mobile: false,
    desktop: true,
    id: "transporte",
    to: {name: "transporte"},
    text: text("transporte"),
    tipos: ["administrador", "supervisor"],
  },
  ["servicios"]: {
    number: 10,
    mobile: false,
    desktop: true,
    id: "servicios",
    icon: "servicios",
    to: {name: "servicios"},
    text: text("servicios"),
    tipos: ["administrador", "supervisor"],
  },
  ["desechos"]: {
    number: 11,
    mobile: false,
    desktop: true,
    id: "desechos",
    icon: "desechos",
    to: {name: "desechos"},
    text: text("desechos"),
    tipos: ["administrador", "supervisor"],
  },
  ["barredores"]: {
    number: 14,
    mobile: false,
    desktop: true,
    id: "barredores",
    icon: "barredores",
    to: {name: "barredores"},
    text: text("barredores"),
    tipos: ["administrador", "cliente", "supervisor"],
  },
  ["rastreadores"]: {
    number: 15,
    mobile: false,
    desktop: true,
    tipos: ["sudo"],
    id: "rastreadores",
    icon: "rastreadores",
    text: text("rastreadores"),
    to: {name: "rastreadores"},
  },
  ["planes"]: {
    number: 16,
    id: "planes",
    mobile: false,
    desktop: true,
    icon: "planes",
    tipos: ["sudo"],
    to: {name: "planes"},
    text: text("planes"),
  },
  ["alertasBarredores"]: {
    number: 17,
    id: "planes",
    mobile: true,
    desktop: false,
    icon: "barredores",
    to: {name: "alertasBarredores"},
    text: text("alertasBarredores"),
    tipos: ["administrador", "supervisor"],
  },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useRutas() {
  const get = computed(() => {
    return rutas;
  });

  const getArray = computed(() => {
    return Object.values(rutas);
  });

  return {get, getArray};
}
