var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.polyline)?_c('GmapPolyline',{attrs:{"path":_vm.polyline,"options":{
      strokeColor: _vm.barredor.color,
      strokeOpacity: 1.0,
      strokeWeight: 4,
      zIndex: 20,
    }},on:{"update:path":function($event){_vm.polyline=$event}}}):_vm._e(),(_vm.ultimaPosicion)?_c('MarkerUltimaPosicion',{attrs:{"zoom":_vm.zoom,"pings":_vm.pings,"horario":_vm.horario,"barredor":_vm.barredor,"historico":_vm.historico,"seleccionados":_vm.seleccionados,"ultima-posicion":_vm.ultimaPosicion},on:{"click:seleccionar":_vm.onClickSeleccionar}}):_vm._e(),(_vm.polyline && _vm.zoom > 15)?_c('GmapMarker',{attrs:{"position":_vm.polyline[0],"icon":{
      url: require('@/assets/svg/icons/fas/fas-map-marker-alt-secondary.svg'),
      size: { width: 32, height: 32, f: 'px', b: 'px' },
      scaledSize: { width: 32, height: 32, f: 'px', b: 'px' },
    }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }