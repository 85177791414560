// composition-api
import {computed, ref} from "vue";
// vuex
import store from "@/store";
// tipos
import {
  Servicio,
  Servicios,
} from "@/typings/store/plugins/easyFirestore/servicios";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useServicios(config?: { ordenarArray?: boolean }) {
  const ordenar = ref(config?.ordenarArray ?? false);

  const setOrdenarArray = (value: boolean) => {
    ordenar.value = value;
  };

  const get = computed(() => {
    return store.getters["servicios/get"] as Servicios;
  });

  const sort = (array: Servicio[]) => {
    array.sort((a, b) => {
      return a.nombre.localeCompare(b.nombre);
    });
    return array;
  };

  const getArray = computed(() => {
    let servicios = store.getters["servicios/getArray"] as Servicio[];
    if (ordenar.value) {
      servicios = sort(servicios);
    }
    return servicios;
  });

  const getById = (id: string): Servicio | null => {
    if (!id) return null;
    return get.value[id] ?? null;
  };

  return {get, getArray, setOrdenarArray, getById};
}
