var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"rounded-input elevation-dark",attrs:{"color":"dialog"}},[_c('v-card-text',[_c('v-row',{staticClass:"pt-4",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"text-h6 metropolis white--text text-center text-capitalize font-weight-bold",attrs:{"cols":"8","offset":"2"}},[_vm._v(" "+_vm._s(_vm.$t("barredores.tab.rutas.informacion.title"))+" ")]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"gradient-error elevation-error rounded-input",attrs:{"x-small":"","width":"44px","height":"44px"},on:{"click":_vm.onClickCerrar}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-close")])],1)],1)],1),_c('v-row',{staticClass:"pt-3",attrs:{"no-gutters":""}},[_c('v-col',[_c('GmapMap',{staticClass:"google-map",attrs:{"center":_vm.ultima,"zoom":17,"options":{
            clickableIcons: false,
            draggableCursor: 'default',
            styles: require('@/assets/json/gmap/MapStyles.json'),
          },"map-type-id":"terrain"}},[_c('GmapPolyline',{attrs:{"path":_vm.path,"options":{
              strokeColor: _vm.color,
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }},on:{"update:path":function($event){_vm.path=$event}}}),(_vm.ultima)?_c('GmapMarker',{attrs:{"position":_vm.ultima}}):_vm._e()],1)],1)],1),_c('v-row',{staticClass:"pt-3",attrs:{"no-gutters":"","align":"center","justify":"space-between"}},[_c('v-col',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"white--text font-weight-bold metropolis text-body-1",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.$t("barredores.tab.rutas.informacion.label.0"))+" ")]),_c('v-col',{staticClass:"pl-2 metropolis text-body-1 white--text",attrs:{"cols":"9"}},[_vm._v(" "+_vm._s(_vm.nombre)+" ")])],1)],1),_c('v-col',{staticClass:"pt-2 pt-md-0"},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"end"}},[_c('v-col',{staticClass:"white--text font-weight-bold metropolis text-body-1 pr-2",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t("barredores.tab.rutas.informacion.label.1"))+" ")]),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"auto"}},[_c('v-card',{staticClass:"rounded-input border-grey",attrs:{"flat":"","color":_vm.color,"width":"40px","height":"40px"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }