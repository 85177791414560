//tipos
import { GetterTree } from "vuex";
import { State } from "@/typings/store/index";
import { Rastreadores } from "@/typings/store/plugins/easyFirestore/rastreadores";

interface RastradoresState {
  all: Rastreadores;
}

const getters: GetterTree<RastradoresState, State> = {
  get(state) {
    return state.all;
  },
  getArray(state) {
    return Object.values(state.all);
  },
};

const rastreadores = {
  firestorePath: "rastreadores",
  firestoreRefType: "collection",
  moduleName: "rastreadores",
  statePropName: "all",
  namespaced: true,

  state: {},
  getters,
  mutations: {},
  actions: {},
};

export default rastreadores;
