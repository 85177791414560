var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"row-buttons flex-grow-0",attrs:{"no-gutters":"","align":"end"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{class:{
        'rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button': true,
        'button-inactive': _vm.tab !== 0,
      },attrs:{"block":"","height":_vm.tab === 0 ? '50px' : '44px',"depressed":"","color":"yellow"},on:{"click":function($event){_vm.tab = 0}}},[_vm._v(" "+_vm._s(_vm.$t("transporte.tab.choferes.text"))+" ")])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{class:{
        'rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button': true,
        'button-inactive': _vm.tab !== 1,
      },attrs:{"color":"purple","block":"","height":_vm.tab === 1 ? '50px' : '44px',"depressed":""},on:{"click":function($event){_vm.tab = 1}}},[_vm._v(" "+_vm._s(_vm.$t("transporte.tab.flota.text"))+" ")])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{class:{
        'rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button': true,
        'button-inactive': _vm.tab !== 2,
      },attrs:{"color":"error","block":"","height":_vm.tab === 2 ? '50px' : '44px',"depressed":""},on:{"click":function($event){_vm.tab = 2}}},[_vm._v(" "+_vm._s(_vm.$t("transporte.tab.rutas.text"))+" ")])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{class:{
        'rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button': true,
        'button-inactive': _vm.tab !== 3,
      },attrs:{"color":"primary","block":"","height":_vm.tab === 3 ? '50px' : '44px',"depressed":""},on:{"click":function($event){_vm.tab = 3}}},[_vm._v(" "+_vm._s(_vm.$t("transporte.tab.identificadores.text"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }