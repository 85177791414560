//tipos
import { ActionTree, GetterTree } from "vuex";
import {
  Usuario,
  Usuarios,
} from "@/typings/store/plugins/easyFirestore/usuarios";
import { State } from "@/typings/store/index";

interface UsuariosState {
  all: Usuarios;
}

const getters: GetterTree<UsuariosState, State> = {
  get(state, getters) {
    return (getters["getArray"] as Array<Usuario>).reduce((docs, doc) => {
      if (!doc?.id) {
        console.error("doc.id == undefined => ", doc);
        return docs;
      }
      docs[doc.id] = doc;
      return docs;
    }, {} as Usuarios);
  },
  getArray(state) {
    return Object.values(state.all).map((doc) => {
      if (!doc.empresa) {
        doc.empresa = "11111111-1";
      }
      return JSON.parse(JSON.stringify(doc)) as Usuario;
    });
  },
};

const actions: ActionTree<UsuariosState, State> = {};

const usuarios = {
  firestorePath: "usuarios",
  firestoreRefType: "collection",
  moduleName: "usuarios",
  statePropName: "all",
  namespaced: true,

  state: {},
  getters,
  mutations: {},
  actions,
};

export default usuarios;
