var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column fill-height"},[_c('v-row',{staticClass:"row-buttons flex-grow-0",attrs:{"no-gutters":"","align":"end"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{class:`rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button ${
          _vm.tab !== 0 ? 'button-inactive' : ''
        }`,attrs:{"block":"","height":_vm.tab === 0 ? '50px' : '44px',"depressed":"","color":"yellow"},on:{"click":function($event){_vm.tab = 0}}},[_vm._v(" "+_vm._s(_vm.t("bascula.dialogo-manual.entrada-salida-manual.tab.entrada.title"))+" ")])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{class:`rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button ${
          _vm.tab !== 1 ? 'button-inactive' : ''
        }`,attrs:{"block":"","height":_vm.tab === 1 ? '50px' : '44px',"depressed":"","color":"purple","disabled":_vm.disabledTabSalida},on:{"click":function($event){_vm.tab = 1}}},[_vm._v(" "+_vm._s(_vm.t("bascula.dialogo-manual.entrada-salida-manual.tab.salida.title"))+" ")])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card',{staticClass:"elevation-black rounded-input rounded-tl-0",attrs:{"color":"dialogDark","height":"100%"}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-tabs-items',{staticClass:"rounded-input rounded-tl-0 dialogDark",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-row',{staticClass:"px-2 pt-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2"},[_c('div',{staticClass:"border-grey rounded-input"},[_c('v-row',{staticClass:"px-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"metropolis white--text font-weight-bold text-body-1",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.t( "bascula.dialogo-manual.entrada-salida-manual.tab.entrada.fecha.label" ))+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","max-width":"290px","min-width":"290px","content-class":"rounded-input elevation-black","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs, value }){return [_c('v-card',_vm._g(_vm._b({staticClass:"card-menu",attrs:{"flat":"","tile":"","color":"transparent","ripple":false,"height":"38px"}},'v-card',attrs,false),on),[_c('v-card-text',{staticClass:"py-1 px-3 fill-height metropolis text-body-1 white--text"},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"text-truncate",attrs:{"cols":"11"}},[_vm._v(" "+_vm._s(_vm.fechaEntrada)+" ")]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"1"}},[_c('svg-icon',{class:`select-icon ${
                                        value ? 'selected' : ''
                                      }`,attrs:{"name":"ionic-md-arrow-dropdown","color":"text","width":"12px","height":"12px"}})],1)],1)],1)],1)]}}]),model:{value:(_vm.menuFechaEntrada),callback:function ($$v) {_vm.menuFechaEntrada=$$v},expression:"menuFechaEntrada"}},[_c('v-date-picker',{staticClass:"calendar metropolis",attrs:{"locale":"es-es","no-title":"","allowed-dates":_vm.$allowedDatesBeforeToday},on:{"input":function($event){_vm.menuFechaEntrada = false}},model:{value:(_vm.fechaEntrada),callback:function ($$v) {_vm.fechaEntrada=$$v},expression:"fechaEntrada"}})],1)],1)],1)],1)]),_c('v-col',{staticClass:"pl-2"},[_c('div',{staticClass:"border-grey rounded-input"},[_c('v-row',{staticClass:"px-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"metropolis white--text font-weight-bold text-body-1",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.t( "bascula.dialogo-manual.entrada-salida-manual.tab.entrada.hora.label" ))+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{staticClass:"metropolis",attrs:{"dark":"","solo":"","flat":"","dense":"","placeholder":_vm.t(
                              'bascula.dialogo-manual.entrada-salida-manual.tab.entrada.hora.placeholder'
                            ),"type":"time","hide-details":"auto","background-color":"transparent","error-messages":_vm.erroresHoraEntrada},model:{value:(_vm.horaEntrada),callback:function ($$v) {_vm.horaEntrada=$$v},expression:"horaEntrada"}})],1)],1)],1)])],1),_c('v-row',{staticClass:"px-2 pt-2",attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"border-grey rounded-input"},[_c('v-row',{staticClass:"px-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"white--text metropolis text-body-1 font-weight-bold",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.t( "bascula.dialogo-manual.entrada-salida-manual.tab.entrada.peso.label" ))+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['#########']),expression:"['#########']"}],staticClass:"text-center metropolis font-weight-bold rounded-input",attrs:{"solo":"","flat":"","dark":"","dense":"","suffix":_vm.t(
                              'bascula.dialogo-manual.entrada-salida-manual.tab.entrada.peso.suffix'
                            ),"background-color":"transparent","placeholder":_vm.t(
                              'bascula.dialogo-manual.entrada-salida-manual.tab.entrada.peso.placeholder'
                            ),"hide-details":"auto","error-messages":_vm.erroresPesoEntrada},model:{value:(_vm.pesoEntrada),callback:function ($$v) {_vm.pesoEntrada=_vm._n($$v)},expression:"pesoEntrada"}})],1)],1)],1)])],1),_c('v-row',{staticClass:"px-2 pt-2",attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"border-grey rounded-input"},[_c('v-row',{staticClass:"px-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"white--text metropolis text-body-1 font-weight-bold",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.t( "bascula.dialogo-manual.entrada-salida-manual.tab.entrada.chofer.label" ))+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-combobox',{staticClass:"metropolis",attrs:{"solo":"","flat":"","dark":"","dense":"","return-object":"","item-text":"nombre","hide-details":"auto","placeholder":_vm.t(
                              'bascula.dialogo-manual.entrada-salida-manual.tab.entrada.chofer.placeholder'
                            ),"background-color":"transparent","items":_vm.arrayChoferes,"error-messages":_vm.erroresChoferEntrada,"menu-props":{
                            contentClass: 'rounded-input elevation-black',
                            transition: 'slide-y-transition',
                          }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{staticClass:"metropolis darker--text text-capitalize"},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"selection",fn:function({ item }){return [_c('span',{staticClass:"metropolis white--text text-capitalize text-truncate"},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"append",fn:function(){return [_c('svg-icon',{attrs:{"name":"ionic-md-arrow-dropdown","color":"text","width":"12px","height":"12px"}})]},proxy:true}]),model:{value:(_vm.choferEntrada),callback:function ($$v) {_vm.choferEntrada=$$v},expression:"choferEntrada"}})],1)],1)],1)])],1),_c('v-row',{staticClass:"px-2 pt-2",attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"border-grey rounded-input"},[_c('v-row',{staticClass:"px-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"white--text metropolis text-body-1 font-weight-bold",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.t( "bascula.dialogo-manual.entrada-salida-manual.tab.entrada.detalle.label" ))+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{staticClass:"metropolis",attrs:{"solo":"","flat":"","dark":"","dense":"","hide-details":"auto","placeholder":_vm.t(
                              'bascula.dialogo-manual.entrada-salida-manual.tab.entrada.detalle.placeholder'
                            ),"background-color":"transparent"},model:{value:(_vm.observacionEntrada),callback:function ($$v) {_vm.observacionEntrada=$$v},expression:"observacionEntrada"}})],1)],1)],1)])],1)],1),_c('v-tab-item',[_c('v-row',{staticClass:"px-2 pt-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2"},[_c('div',{staticClass:"border-grey rounded-input"},[_c('v-row',{staticClass:"px-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"metropolis white--text font-weight-bold text-body-1",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.t( "bascula.dialogo-manual.entrada-salida-manual.tab.salida.fecha.label" ))+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","max-width":"290px","min-width":"290px","content-class":"rounded-input elevation-black","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs, value }){return [_c('v-card',_vm._g(_vm._b({staticClass:"card-menu",attrs:{"flat":"","tile":"","color":"transparent","ripple":false,"height":"38px"}},'v-card',attrs,false),on),[_c('v-card-text',{staticClass:"py-1 px-3 fill-height metropolis text-body-1 white--text"},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"text-truncate",attrs:{"cols":"11"}},[_vm._v(" "+_vm._s(_vm.fechaSalida)+" ")]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"1"}},[_c('svg-icon',{class:`select-icon ${
                                        value ? 'selected' : ''
                                      }`,attrs:{"name":"ionic-md-arrow-dropdown","color":"text","width":"12px","height":"12px"}})],1)],1)],1)],1)]}}]),model:{value:(_vm.menuFechaSalida),callback:function ($$v) {_vm.menuFechaSalida=$$v},expression:"menuFechaSalida"}},[_c('v-date-picker',{staticClass:"calendar metropolis",attrs:{"locale":"es-es","no-title":"","allowed-dates":_vm.$allowedDatesBeforeToday},on:{"input":function($event){_vm.menuFechaSalida = false}},model:{value:(_vm.fechaSalida),callback:function ($$v) {_vm.fechaSalida=$$v},expression:"fechaSalida"}})],1)],1)],1)],1)]),_c('v-col',{staticClass:"pl-2"},[_c('div',{staticClass:"border-grey rounded-input"},[_c('v-row',{staticClass:"px-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"metropolis white--text font-weight-bold text-body-1",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.t( "bascula.dialogo-manual.entrada-salida-manual.tab.salida.hora.label" ))+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{staticClass:"metropolis",attrs:{"solo":"","flat":"","dark":"","dense":"","placeholder":_vm.t(
                              'bascula.dialogo-manual.entrada-salida-manual.tab.salida.hora.placeholder'
                            ),"type":"time","hide-details":"auto","background-color":"transparent"},model:{value:(_vm.horaSalida),callback:function ($$v) {_vm.horaSalida=$$v},expression:"horaSalida"}})],1)],1)],1)])],1),_c('v-row',{staticClass:"px-2 pt-2",attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"border-grey rounded-input"},[_c('v-row',{staticClass:"px-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"white--text metropolis text-body-1 font-weight-bold",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.t( "bascula.dialogo-manual.entrada-salida-manual.tab.salida.peso.label" ))+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['#########']),expression:"['#########']"}],staticClass:"text-center metropolis font-weight-bold rounded-input",attrs:{"solo":"","flat":"","dark":"","dense":"","suffix":_vm.t(
                              'bascula.dialogo-manual.entrada-salida-manual.tab.salida.peso.suffix'
                            ),"background-color":"transparent","placeholder":_vm.t(
                              'bascula.dialogo-manual.entrada-salida-manual.tab.salida.peso.placeholder'
                            ),"error-messages":_vm.erroresPesoSalida,"hide-details":"auto"},model:{value:(_vm.pesoSalida),callback:function ($$v) {_vm.pesoSalida=_vm._n($$v)},expression:"pesoSalida"}})],1)],1)],1)])],1),_c('v-row',{staticClass:"px-2 pt-2",attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"border-grey rounded-input"},[_c('v-row',{staticClass:"px-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"white--text metropolis text-body-1 font-weight-bold",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.t( "bascula.dialogo-manual.entrada-salida-manual.tab.salida.chofer.label" ))+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-combobox',{staticClass:"metropolis",attrs:{"solo":"","flat":"","dark":"","dense":"","return-object":"","item-text":"nombre","hide-details":"auto","placeholder":_vm.t(
                              'bascula.dialogo-manual.entrada-salida-manual.tab.salida.chofer.placeholder'
                            ),"error-messages":_vm.erroresChoferSalida,"background-color":"transparent","items":_vm.arrayChoferes,"menu-props":{
                            contentClass: 'rounded-input elevation-black',
                            transition: 'slide-y-transition',
                          }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{staticClass:"metropolis darker--text text-capitalize"},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"selection",fn:function({ item }){return [_c('span',{staticClass:"metropolis white--text text-capitalize text-truncate"},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"append",fn:function(){return [_c('svg-icon',{attrs:{"name":"ionic-md-arrow-dropdown","color":"text","width":"12px","height":"12px"}})]},proxy:true}]),model:{value:(_vm.choferSalida),callback:function ($$v) {_vm.choferSalida=$$v},expression:"choferSalida"}})],1)],1)],1)])],1),_c('v-row',{staticClass:"px-2 pt-2",attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"border-grey rounded-input"},[_c('v-row',{staticClass:"px-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"white--text metropolis text-body-1 font-weight-bold",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.t( "bascula.dialogo-manual.entrada-salida-manual.tab.salida.detalle.label" ))+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{staticClass:"metropolis",attrs:{"solo":"","flat":"","dark":"","dense":"","hide-details":"","placeholder":_vm.t(
                              'bascula.dialogo-manual.entrada-salida-manual.tab.salida.detalle.placeholder'
                            ),"background-color":"transparent"},model:{value:(_vm.observacionSalida),callback:function ($$v) {_vm.observacionSalida=$$v},expression:"observacionSalida"}})],1)],1)],1)])],1)],1)],1),_c('v-row',{staticClass:"px-2 pt-4 pb-2",attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"elevation-black gradient-primary rounded-input px-8",attrs:{"large":""},on:{"click":_vm.guardar}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('svg-icon',{attrs:{"name":"ag-save","color":"white","width":"16px","height":"16px"}})],1),_c('v-col',{staticClass:"metropolis text-initial font-weight-bold text-body-1 white--text pl-2",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.t( "bascula.dialogo-manual.entrada-salida-manual.boton-guardar.text" ))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }