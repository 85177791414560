var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0 ma-0"},[_c('v-row',{attrs:{"justify":"start","no-gutters":"","align":"end"}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"metropolis rounded-input elevation-input",attrs:{"hide-details":"","dense":"","solo":"","clearable":"","color":"text","placeholder":_vm.$t('transporte.search.placeholder')},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('svg-icon',{attrs:{"name":"ag-search","color":"text"}})]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.tab === 1)?_c('v-col',{staticClass:"pl-2",attrs:{"cols":"4"}},[_c('v-container',{staticClass:"pa-0 ma-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"metropolis text--text text-body-1 px-5",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t("transporte.filtro.tipo-vehiculo.label"))+" ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{staticClass:"metropolis rounded-input elevation-input",attrs:{"dense":"","solo":"","clearable":"","hide-details":"","color":"text","placeholder":_vm.$t('transporte.filtro.tipo-vehiculo.placeholder'),"items":_vm.tiposVehiculos,"menu-props":{
                contentClass: 'rounded-input elevation-black',
                transition: 'slide-y-transition',
              }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{staticClass:"metropolis darker--text text-body-1"},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"selection",fn:function({ item }){return [_c('span',{staticClass:"metropolis darker--text text-body-1"},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"append",fn:function(){return [_c('svg-icon',{attrs:{"name":"ionic-md-arrow-dropdown","color":"text","width":"12px","height":"12px"}})]},proxy:true}],null,false,23233783),model:{value:(_vm.filtroTipoVehiculo),callback:function ($$v) {_vm.filtroTipoVehiculo=$$v},expression:"filtroTipoVehiculo"}})],1)],1)],1)],1):_vm._e(),(_vm.tab === 2)?_c('v-col',{staticClass:"pl-2",attrs:{"cols":"4"}},[_c('v-container',{staticClass:"pa-0 ma-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"metropolis text--text text-body-1 px-5",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t("transporte.filtro.tipo-ruta.label"))+" ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{staticClass:"metropolis rounded-input elevation-input",attrs:{"dense":"","solo":"","clearable":"","hide-details":"","color":"text","placeholder":_vm.$t('transporte.filtro.tipo-ruta.placeholder'),"items":_vm.tiposRutas,"menu-props":{
                contentClass: 'rounded-input elevation-black',
                transition: 'slide-y-transition',
              }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{staticClass:"metropolis darker--text text-body-1"},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"selection",fn:function({ item }){return [_c('span',{staticClass:"metropolis darker--text text-body-1"},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"append",fn:function(){return [_c('svg-icon',{attrs:{"name":"ionic-md-arrow-dropdown","color":"text","width":"12px","height":"12px"}})]},proxy:true}],null,false,23233783),model:{value:(_vm.filtroTipoRutaPesaje),callback:function ($$v) {_vm.filtroTipoRutaPesaje=$$v},expression:"filtroTipoRutaPesaje"}})],1)],1)],1)],1):_vm._e(),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"4"}},[_c('v-container',{staticClass:"pa-0 ma-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"metropolis text--text text-body-1 px-5",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t("transporte.filtro.estado.label"))+" ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{staticClass:"metropolis rounded-input elevation-input",attrs:{"dense":"","solo":"","clearable":"","hide-details":"","color":"text","placeholder":_vm.$t('transporte.filtro.estado.placeholder'),"items":_vm.estados,"menu-props":{
                contentClass: 'rounded-input elevation-black',
                transition: 'slide-y-transition',
              }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{staticClass:"metropolis darker--text text-body-1"},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"selection",fn:function({ item }){return [_c('span',{staticClass:"metropolis darker--text text-body-1"},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"append",fn:function(){return [_c('svg-icon',{attrs:{"name":"ionic-md-arrow-dropdown","color":"text","width":"12px","height":"12px"}})]},proxy:true}]),model:{value:(_vm.filtroEstado),callback:function ($$v) {_vm.filtroEstado=$$v},expression:"filtroEstado"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }