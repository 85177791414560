// composition-api
import { computed, ref } from "vue";
// helpers
import { isDate } from "@/helpers/date";
// tipos
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import { usePesajes } from "./pesajes";

interface MatrizKgM3 {
  // string cliente.id
  [clienteId: string]: {
    [fecha: number]: number; // fecha en 00:00:00.000 .valueOf()
  };
}

const defaultKgM3 = 310;

const kgM3 = ref<MatrizKgM3>({});

const getM3 = (
  peso: number,
  capacidad: number,
  cobro: number,
  _kgM3: number
): number => {
  if (cobro && !isNaN(cobro) && cobro > 0) {
    return cobro;
  }
  if (!_kgM3 || isNaN(_kgM3) || _kgM3 <= 0) {
    console.log("error: kgM3 = ", _kgM3);
    return -1;
  }
  if (!peso || isNaN(peso)) {
    console.log("error: peso = ", peso);
    return -1;
  }
  const m3Local = Math.ceil(peso / _kgM3);
  if (!capacidad || isNaN(capacidad) || capacidad <= 0) {
    return m3Local;
  }
  if (!defaultKgM3 || isNaN(defaultKgM3) || defaultKgM3 <= 0) {
    console.log("error: kgM3Default = ", defaultKgM3);
    return -1;
  }
  let m3General = Math.ceil(peso / defaultKgM3);
  if (m3General > capacidad) {
    m3General = capacidad;
  }
  const m3Prom = Math.ceil((m3Local + m3General) / 2);
  return Math.min(m3Prom, capacidad);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useKgM3() {
  const _pesajes = usePesajes();

  const get = computed(() => {
    return kgM3.value;
  });

  const setKgM3 = (data: { id: string; fecha: number; kgM3: number }) => {
    const cliente = kgM3.value[data.id];
    const valor = cliente ? cliente[data.fecha] : 0;
    if (valor !== data.kgM3) {
      kgM3.value = Object.assign({}, kgM3, {
        [data.id]: { [data.fecha]: data.kgM3 },
      });
    }
  };

  const getArrayPesajesSorted = (cliente: Cliente, fechaActual: Date) => {
    fechaActual.setHours(0, 0, 0, 0);
    const fechaInicio = new Date(fechaActual);
    const fechaFin = new Date(fechaActual);
    fechaFin.setDate(fechaFin.getDate() + 1);
    const pesajes = _pesajes.getArray.value.filter((item) => {
      let fecha = new Date(item.entrada.fecha);
      if (!isDate(item.entrada.fecha)) {
        fecha = new Date(item.entrada.fecha.replaceAll("-", "/"));
      }
      const fechaValida = fecha >= fechaInicio && fecha < fechaFin;
      const pesoValido =
        item.entrada.peso &&
        item.salida.peso &&
        item.entrada.peso >= item.salida.peso;
      const clienteValido = item.cliente?.id
        ? item.cliente.id === cliente.id
        : false;
      const capacidadValida = !!item.capacidad;
      return fechaValida && pesoValido && capacidadValida && clienteValido;
    });
    pesajes.sort((a, b) => {
      const pesoEntradaA = a.entrada.peso;
      const pesoSalidaA = a.salida.peso ? a.salida.peso : pesoEntradaA;
      const pesoA = pesoEntradaA - pesoSalidaA;
      const pesoEntradaB = b.entrada.peso;
      const pesoSalidaB = b.salida.peso ? b.salida.peso : pesoEntradaB;
      const pesoB = pesoEntradaB - pesoSalidaB;
      return pesoB - pesoA;
    });
    return pesajes;
  };

  const getKgM3By = async (data: { cliente: Cliente | null; fecha: Date }) => {
    return new Promise<number>((res): void => {
      const cliente = data.cliente;
      if (!cliente?.id) {
        return res(defaultKgM3);
      }
      const fechaActual = data.fecha;
      const pesajes = getArrayPesajesSorted(cliente, fechaActual);
      const pesaje = pesajes[0];
      if (!pesaje || !pesaje.entrada.peso || !pesaje.salida.peso) {
        return res(defaultKgM3);
      }
      const pesoEntrada = pesaje.entrada.peso;
      const pesoSalida = pesaje.salida.peso;
      const peso = pesoEntrada - pesoSalida;
      const resultado = Math.floor(peso / pesaje.capacidad);
      if (cliente.id) {
        setKgM3({
          id: cliente.id,
          fecha: fechaActual.valueOf(),
          kgM3: resultado,
        });
      }
      return res(resultado);
    });
  };

  return { defaultKgM3, get, getKgM3By, getM3 };
}
