// modulos
import modulos from "./modulos";
// helpers
import { log } from "@/helpers/env";
// tipos
import { Store } from "vuex";
import { State } from "@/typings/store";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";

const cerrarCanalesBD = async (
  store: Store<State>,
  usuario: Usuario
): Promise<void> => {
  const promesas = modulos(usuario.tipo).map((modulo) => {
    const valido = modulo.permisos.every((permiso) => {
      return usuario.permisos[permiso];
    });
    if (valido) {
      return store
        .dispatch(modulo.nombre + "/closeDBChannel", {
          clearModule: true,
        })
        .catch((error) => {
          if (log) {
            console.error(error);
          }
        });
    }
    return null;
  });
  await Promise.all(promesas);
};

export default cerrarCanalesBD;
