// helpers
import { env } from "@/helpers/env";
// tipos
import { TipoUsuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import {
  Modulos,
  WhereModulo,
} from "@/typings/store/plugins/easyFirestore/iniciar/modulos";

// pesajes where
const getWherePesajesDias = (dias: number): WhereModulo => {
  const fecha = new Date();
  fecha.setHours(0, 0, 0, 0);
  fecha.setDate(fecha.getDate() - dias);
  return [
    {
      name: "entrada.fecha",
      comparacion: ">=",
      value: fecha.toISOString(),
      user: false,
    },
  ];
};

const getWherePesajes = (): WhereModulo => {
  const fecha = new Date();
  fecha.setHours(0, 0, 0, 0);
  fecha.setDate(1);
  fecha.setMonth(fecha.getMonth() - 3);
  return [
    {
      name: "entrada.fecha",
      comparacion: ">=",
      value: fecha.toISOString(),
      user: false,
    },
  ];
};

// archivos where
const getWhereArchivos = (): WhereModulo => {
  const fecha = new Date();
  fecha.setHours(0, 0, 0, 0);
  fecha.setDate(1);
  fecha.setMonth(fecha.getMonth() - 3);
  return [
    {
      name: "fecha",
      comparacion: ">=",
      value: fecha.toISOString(),
      user: false,
    },
  ];
};

// pingsRastreador where
const getWherePingsRastreador = (): WhereModulo => {
  const fecha = new Date();
  fecha.setHours(0, 0, 0, 0);
  return [
    {
      name: "fecha",
      comparacion: ">=",
      value: fecha.toISOString(),
      user: false,
    },
  ];
};

// rastreadores Where
const getWhereRastreadores = (tipoUsuario: TipoUsuario): WhereModulo => {
  return tipoUsuario !== "sudo"
    ? [{ name: "empresa", comparacion: "==", value: "empresa", user: true }]
    : [];
};

// empresas Where
const getWhereEmpresas = (tipoUsuario: TipoUsuario): WhereModulo => {
  return tipoUsuario !== "sudo"
    ? [{ name: "id", comparacion: "==", value: "empresa", user: true }]
    : [];
};

// si permisos = [] todos pueden acceder; value de parametro obtiene su valor del objeto Usuario (id = usuario.id)
const modulos = (tipoUsuario: TipoUsuario): Modulos => {
  return [
    {
      mobile: true,
      parametros: [],
      nombre: "usuarios",
      permisos: ["usuarios"],
      where: [
        { name: "empresa", comparacion: "==", value: "empresa", user: true },
      ],
    },
    {
      permisos: [],
      mobile: true,
      nombre: "empresas",
      parametros: [{ name: "env", value: env, user: false }],
      where: getWhereEmpresas(tipoUsuario),
    },
    {
      permisos: [],
      mobile: false,
      nombre: "vehiculos",
      parametros: [
        { name: "env", value: env, user: false },
        { name: "idEmpresa", value: "empresa", user: true },
      ],
      where: [],
    },
    {
      permisos: [],
      mobile: false,
      nombre: "choferes",
      parametros: [
        { name: "env", value: env, user: false },
        { name: "idEmpresa", value: "empresa", user: true },
      ],
      where: [],
    },
    {
      permisos: [],
      mobile: false,
      nombre: "clientes",
      parametros: [
        { name: "env", value: env, user: false },
        { name: "idEmpresa", value: "empresa", user: true },
      ],
      where: [],
    },
    {
      mobile: false,
      nombre: "desechos",
      permisos: [],
      parametros: [
        { name: "env", value: env, user: false },
        { name: "idEmpresa", value: "empresa", user: true },
      ],
      where: [],
    },
    {
      mobile: false,
      nombre: "identificadores",
      permisos: [],
      parametros: [
        { name: "env", value: env, user: false },
        { name: "idEmpresa", value: "empresa", user: true },
      ],
      where: [],
    },
    {
      mobile: false,
      nombre: "servicios",
      permisos: [],
      parametros: [
        { name: "env", value: env, user: false },
        { name: "idEmpresa", value: "empresa", user: true },
      ],
      where: [],
    },
    {
      mobile: false,
      nombre: "rutasPesaje",
      permisos: [],
      parametros: [
        { name: "env", value: env, user: false },
        { name: "idEmpresa", value: "empresa", user: true },
      ],
      where: [],
    },
    {
      mobile: true,
      nombre: "barredores",
      permisos: ["barredores"],
      parametros: [
        { name: "env", value: env, user: false },
        { name: "idEmpresa", value: "empresa", user: true },
      ],
      where: [],
    },
    {
      mobile: false,
      nombre: "rastreadores",
      permisos: ["barredores", "rastreadores"],
      parametros: [],
      where: getWhereRastreadores(tipoUsuario),
    },
    {
      mobile: false,
      nombre: "rutasBarredor",
      permisos: ["barredores"],
      parametros: [
        { name: "env", value: env, user: false },
        { name: "idEmpresa", value: "empresa", user: true },
      ],
      where: [],
    },
    {
      mobile: false,
      nombre: "planes",
      permisos: [],
      parametros: [],
      where: [],
    },
    {
      mobile: false,
      nombre: "pesajes",
      permisos: ["pesajes", "monitoreo", "reportes"],
      parametros: [
        { name: "env", value: env, user: false },
        { name: "idEmpresa", value: "empresa", user: true },
      ],
      where: getWherePesajes(),
    },
    {
      mobile: false,
      nombre: "pesajes",
      permisos: ["bascula"],
      parametros: [
        { name: "env", value: env, user: false },
        { name: "idEmpresa", value: "empresa", user: true },
      ],
      where: getWherePesajesDias(2),
    },
    {
      mobile: true,
      nombre: "pingsRastreador",
      permisos: ["barredores"],
      parametros: [
        { name: "env", value: env, user: false },
        { name: "idEmpresa", value: "empresa", user: true },
      ],
      where: getWherePingsRastreador(),
    },
    {
      mobile: false,
      nombre: "archivos",
      permisos: [],
      parametros: [
        { name: "env", value: env, user: false },
        { name: "idEmpresa", value: "empresa", user: true },
      ],
      where: getWhereArchivos(),
    },
  ];
};

export default modulos;
