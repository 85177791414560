// composition-api
import { computed } from "vue";
// vuex
import store from "@/store";
// tipos
import { Permisos } from "@/typings/store/modules/permisos";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import { Empresa } from "@/typings/store/plugins/easyFirestore/empresas";
import { Ruta } from "@/typings/store/modules/rutas";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useUsuario() {
  const get = computed(() => {
    return store.getters["usuario/get"] as Usuario;
  });

  const getEmpresa = computed(() => {
    return store.getters['usuario/getEmpresa'] as Empresa;
  });

  const getPermisos = computed(() => {
    return store.getters["usuario/getPermisos"] as Permisos;
  });

  const getRutas = computed(() => {
    return store.getters["usuario/getRutas"] as Ruta[];
  });

  return { get, getEmpresa, getPermisos, getRutas };
}
