// composition-api
import { computed } from "vue";
// vuex
import store from "@/store";
// tipos
import {
  Empresa,
  Empresas,
} from "@/typings/store/plugins/easyFirestore/empresas";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useEmpresas() {
  const get = computed(() => {
    return store.getters["empresas/get"] as Empresas;
  });

  const getById = (id: string): Empresa | null => {
    if (!id) return null;
    return get.value[id] ?? null;
  };

  return { get, getById };
}
