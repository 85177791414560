// composition-api
import {computed, ref, watch} from "vue";
// pouchdb
import PouchDB from "pouchdb";
// vuex
import store from "@/store";
// helpers
import {env, log} from "@/helpers/env";
// composables
import {useUsuario} from "./usuario";
// tipos
import {
  Pesaje,
  PesajeSet,
  PesajePatch,
  Pesajes,
  PesajeData,
} from "@/typings/store/plugins/easyFirestore/pesajes";
import {Cliente} from "@/typings/store/plugins/easyFirestore/clientes";
import {Vehiculo} from "@/typings/store/plugins/easyFirestore/vehiculos";
import {Servicio} from "@/typings/store/plugins/easyFirestore/servicios";
import {Desecho} from "@/typings/store/plugins/easyFirestore/desechos";
import {Archivo} from "@/typings/store/plugins/easyFirestore/archivos";

interface IndexCliente {
  [nombre: string]: Cliente;
}

interface IndexServicio {
  [nombre: string]: Servicio;
}

interface IndexDesecho {
  [nombre: string]: Desecho;
}

type PesajeOld = Pesaje & {
  camion?: Vehiculo | null;
};

interface PathVariables {
  [name: string]: string;
}

type ComparacionWhere = "==" | ">=" | "<=";
type ValorWhere = string | number | boolean | null;

type WhereClause = Array<[string, ComparacionWhere, ValorWhere]>;
type OrderByClause = Array<string>;

interface Clauses {
  where?: WhereClause;
  orderBy?: OrderByClause;
}

type PesajeLocal = Pesaje & {
  _id?: string;
  _rev?: string;
};

const actulizarDatosGenerales = (doc: PesajePatch, pesajeData: PesajeData) => {
  if (pesajeData.vehiculo) {
    doc.vehiculo = pesajeData.vehiculo;
  }
  if (pesajeData.capacidad) {
    doc.capacidad = pesajeData.capacidad;
  }
  if (pesajeData.cobro) {
    doc.cobro = pesajeData.cobro;
  }
  if (pesajeData.ruta) {
    doc.ruta = pesajeData.ruta;
  }
  if (pesajeData.cliente) {
    doc.cliente = pesajeData.cliente;
  }
  if (pesajeData.desecho) {
    doc.desecho = pesajeData.desecho;
  }
  if (pesajeData.servicio) {
    doc.servicio = pesajeData.servicio;
  }
};

const actualizarDatosEntrada = (doc: PesajePatch, pesajeData: PesajeData) => {
  if (pesajeData.choferEntrada) {
    doc.entrada = Object.assign({}, doc.entrada, {
      chofer: pesajeData.choferEntrada,
    });
  }
  if (pesajeData.fechaEntrada) {
    doc.entrada = Object.assign({}, doc.entrada, {
      fecha: pesajeData.fechaEntrada,
    });
  }
  if (pesajeData.pesoEntrada) {
    doc.entrada = Object.assign({}, doc.entrada, {
      peso: pesajeData.pesoEntrada,
    });
  }
  if (pesajeData.observacionEntrada) {
    doc.entrada = Object.assign({}, doc.entrada, {
      observacion: pesajeData.observacionEntrada,
    });
  }
};

const actualizarDatosSalida = (doc: PesajePatch, pesajeData: PesajeData) => {
  if (pesajeData.choferSalida) {
    doc.salida = Object.assign({}, doc.salida, {
      chofer: pesajeData.choferSalida,
    });
  }
  if (pesajeData.fechaSalida) {
    doc.salida = Object.assign({}, doc.salida, {
      fecha: pesajeData.fechaSalida,
    });
  }
  if (pesajeData.pesoSalida) {
    doc.salida = Object.assign({}, doc.salida, {
      peso: pesajeData.pesoSalida,
    });
  }
  if (pesajeData.observacionSalida) {
    doc.salida = Object.assign({}, doc.salida, {
      observacion: pesajeData.observacionSalida,
    });
  }
};

const pesajeDataToPatch = (id: string, pesajeData: PesajeData): PesajePatch => {
  const doc: PesajePatch = {
    id: id,
    sincronizacion: {
      enviado: new Date().toISOString(),
      recibido: null,
    },
  };
  actulizarDatosGenerales(doc, pesajeData);
  actualizarDatosEntrada(doc, pesajeData);
  actualizarDatosSalida(doc, pesajeData);
  if (pesajeData.estado) {
    doc.estado = pesajeData.estado;
  }
  return doc;
};

const pesajeDataToSet = (
  _pesajeData: PesajeData,
  _fotos: Archivo[],
): PesajeSet => {
  return {
    fotos: _fotos,
    vehiculo: _pesajeData.vehiculo || null,
    capacidad: _pesajeData.capacidad || -1,
    cobro: _pesajeData.cobro || -1,
    ruta: _pesajeData.ruta || null,
    cliente: _pesajeData.cliente || null,
    servicio: _pesajeData.servicio || null,
    desecho: _pesajeData.desecho || null,
    entrada: {
      chofer: _pesajeData.choferEntrada || null,
      fecha: _pesajeData.fechaEntrada || new Date().toISOString(),
      peso: _pesajeData.pesoEntrada || 0,
      observacion: _pesajeData.observacionEntrada || "",
    },
    salida: {
      chofer: _pesajeData.choferSalida || null,
      fecha: _pesajeData.fechaSalida || "",
      peso: _pesajeData.pesoSalida || 0,
      observacion: _pesajeData.observacionSalida || "",
    },
    estado: "salida",
    sincronizacion: {
      enviado: new Date().toISOString(),
      recibido: null,
    },
  };
};

const getArrayClientes = computed(() => {
  return store.getters["clientes/getArray"] as Cliente[];
});

const getArrayServicios = computed(() => {
  return store.getters["servicios/getArray"] as Servicio[];
});

const getArrayDesechos = computed(() => {
  return store.getters["desechos/getArray"] as Desecho[];
});

const getIndexCliente = computed(() => {
  if (log) console.time("composables.pesajes.getIndexCliente");
  const clientes = getArrayClientes.value;
  const indexCliente: IndexCliente = {};
  clientes.forEach((cliente) => {
    const nombre = cliente.nombre?.toLowerCase() ?? "";
    if (nombre) {
      indexCliente[nombre] = cliente;
    }
  });
  if (log) console.timeEnd("composables.pesajes.getIndexCliente");
  return indexCliente;
});

const getIndexServicio = computed(() => {
  if (log) console.time("composables.pesajes.getIndexServicio");
  const servicios = getArrayServicios.value;
  const indexServicio: IndexServicio = {};
  servicios.forEach((servicio) => {
    const nombre = servicio.nombre?.toLowerCase() ?? "";
    if (nombre) {
      indexServicio[nombre] = servicio;
    }
  });
  if (log) console.timeEnd("composables.pesajes.getIndexServicio");
  return indexServicio;
});

const getIndexDesecho = computed(() => {
  if (log) console.time("composables.pesajes.getIndexDesecho");
  const desechos = getArrayDesechos.value;
  const indexDesecho: IndexDesecho = {};
  desechos.forEach((desecho) => {
    const nombre = desecho.nombre?.toLowerCase() ?? "";
    if (nombre) {
      indexDesecho[nombre] = desecho;
    }
  });
  if (log) console.timeEnd("composables.pesajes.getIndexDesecho");
  return indexDesecho;
});

const corregirVehiculo = (pesaje: PesajeOld) => {
  if (pesaje.camion) {
    pesaje.vehiculo = pesaje.camion;
    delete pesaje.camion;
  }
  return pesaje;
};

const corregirCliente = (pesaje: PesajeOld) => {
  const indexCliente = getIndexCliente.value;
  if (pesaje.cliente?.nombre) {
    const nombre = pesaje.cliente.nombre.toLowerCase();
    const cliente = indexCliente[nombre];
    if (nombre && cliente) {
      pesaje.cliente = cliente;
    }
  } else {
    pesaje.cliente = null;
  }
  return pesaje;
};

const corregirServicio = (pesaje: PesajeOld) => {
  const indexServicio = getIndexServicio.value;
  if (pesaje.servicio?.nombre) {
    const nombre = pesaje.servicio.nombre.toLowerCase();
    const servicio = indexServicio[nombre];
    if (nombre && servicio) {
      pesaje.servicio = servicio;
    }
  } else {
    pesaje.servicio = null;
  }
  return pesaje;
};

const corregirDesecho = (pesaje: PesajeOld) => {
  const indexDesecho = getIndexDesecho.value;
  if (pesaje.desecho?.nombre) {
    const nombre = pesaje.desecho.nombre.toLowerCase();
    const desecho = indexDesecho[nombre];
    if (nombre && desecho) {
      pesaje.desecho = desecho;
    }
  } else {
    pesaje.desecho = null;
  }
  return pesaje;
};

const corregirSincronizacion = (pesaje: PesajeOld) => {
  if (!pesaje.sincronizacion) {
    pesaje.sincronizacion = {
      enviado: new Date().toISOString(),
      recibido: new Date().toISOString(),
    };
  }
  return pesaje;
};

const fnMapPesaje = (pesaje: Pesaje) => {
  let pesajeCorregido = JSON.parse(JSON.stringify(pesaje)) as PesajeOld;
  pesajeCorregido = corregirVehiculo(pesajeCorregido);
  pesajeCorregido = corregirCliente(pesajeCorregido);
  pesajeCorregido = corregirServicio(pesajeCorregido);
  pesajeCorregido = corregirDesecho(pesajeCorregido);
  pesajeCorregido = corregirSincronizacion(pesajeCorregido);
  return pesajeCorregido as Pesaje;
};

const getPesajes = computed(() => {
  return store.getters["pesajes/get"] as Pesajes;
});
const pesajesActualizados = ref(false);

const getArray = ref<Pesaje[]>([]);
const procesandoGetArray = ref(false);

watch(getPesajes, () => {
  if (log) console.log("composables.pesajes.watch.getPesajes");
  pesajesActualizados.value = true;
}, {deep: true});

setInterval(() => {
  if (pesajesActualizados.value && !procesandoGetArray.value) {
    procesandoGetArray.value = true;
    pesajesActualizados.value = false;
    if (log) console.time("composables.pesajes.getArray");
    const pesajes = getPesajes.value;
    const resultado = Object.values(pesajes).map(fnMapPesaje);
    if (log) console.timeEnd("composables.pesajes.getArray");
    getArray.value = resultado;
    procesandoGetArray.value = false;
  }
}, 1000);

const _usuario = useUsuario();

const getDB = computed(() => {
  const idEmpresa = _usuario.getEmpresa.value?.id;
  if (!idEmpresa) {
    return null;
  }
  return new PouchDB<Pesaje>(`${env}/v1/empresas/${idEmpresa}/pesajes`);
});

const fnReducePesaje = (docs: Pesajes, doc: Pesaje) => {
  if (!doc?.id) {
    console.error("doc.id == undefined => ", doc);
    return docs;
  }
  docs[doc.id] = doc;
  return docs;
};

const get = computed(() => {
  if (log) console.time("composables.pesajes.get");
  const resultado = getArray.value.reduce(fnReducePesaje, {} as Pesajes);
  if (log) console.timeEnd("composables.pesajes.get");
  return resultado;
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function usePesajes() {
  const set = async (
    doc: Pesaje | PesajeSet | PesajePatch,
  ): Promise<string> => {
    const id: string = await store.dispatch("pesajes/set", doc);
    const remoteDoc = getById(id);
    if (!remoteDoc) {
      console.error("remoteDoc == undefined => ", doc);
      return "";
    }
    const db = getDB.value;
    if (!db) {
      console.error("undefined localDB");
      return id;
    }
    const localDoc = await db.get(id).catch(() => {
      return {};
    });
    const newDoc = Object.assign({_id: id}, localDoc, remoteDoc);
    await db.put(newDoc).catch((err) => {
      console.error("db.put => ", err);
    });
    return id;
  };

  const drop = async (id: string): Promise<string> => {
    return store.dispatch("pesajes/delete", id);
  };

  const getById = (id: string) => {
    if (!id) {
      return null;
    }
    const doc = get.value[id];
    if (!doc) {
      return null;
    }
    return JSON.parse(JSON.stringify(doc)) as Pesaje;
  };

  const fetchAndAdd = async (config?: {
    pathVariables?: PathVariables;
    clauses?: Clauses;
  }) => {
    return store.dispatch("pesajes/fetchAndAdd", config);
  };

  const getArrayLocal = async () => {
    if (log) console.time("composables.pesajes.usePesajes.getArrayLocal");
    const db = getDB.value;
    if (!db) {
      console.error("undefined localDB");
      return [];
    }
    const result = await db
    .allDocs({
      include_docs: true,
    })
    .catch(() => {
      return null;
    });
    if (!result) {
      return [];
    }
    const resultado = result.rows.map((row) => {
      const doc = row.doc as { _id?: string; _rev?: string };
      delete doc._id;
      delete doc._rev;
      return doc as Pesaje;
    });
    if (log) console.timeEnd("composables.pesajes.usePesajes.getArrayLocal");
    return resultado;
  };

  const getLocal = async () => {
    if (log) console.time("composables.pesajes.usePesajes.getLocal");
    const arrayLocal = await getArrayLocal();
    const resultado = arrayLocal.reduce(fnReducePesaje, {} as Pesajes);
    if (log) console.timeEnd("composables.pesajes.usePesajes.getLocal");
    return resultado;
  };

  const getLocalById = async (id: string) => {
    if (!id) {
      return null;
    }
    const db = getDB.value;
    if (!db) {
      console.error("undefined localDB");
      return null;
    }
    const doc: PesajeLocal | null = await db.get(id).catch(() => {
      return null;
    });
    if (!doc) {
      return null;
    }
    delete doc._id;
    delete doc._rev;
    return doc as Pesaje;
  };

  const restoreLocalById = async (id: string) => {
    if (!id) {
      console.error("id == undefined");
      return "";
    }
    const doc = await getLocalById(id);
    if (!doc) {
      console.error("doc == undefined");
      return "";
    }
    return set(doc);
  };

  const resynchronize = async (id: string) => {
    const pesajePatch: PesajePatch = {
      id,
      sincronizacion: {
        enviado: new Date().toISOString(),
        recibido: null,
      },
    };
    await set(pesajePatch);
  };

  return {
    get,
    getArray,
    set,
    drop,
    getById,
    fetchAndAdd,
    pesajeDataToSet,
    pesajeDataToPatch,
    getLocal,
    resynchronize,
    getArrayLocal,
    getLocalById,
    restoreLocalById,
  };
}
