// vuex
import { ActionTree, GetterTree, MutationTree } from "vuex";
// tipos
import { State } from "@/typings/store/index";
import { StateCargando } from "@/typings/store/modules/cargando";

const state: StateCargando = {
  cargando: true,
  error: false,
};

const getters: GetterTree<StateCargando, State> = {
  get(_state) {
    return _state.cargando;
  },
  getError(_state) {
    return _state.error;
  },
};

const mutations: MutationTree<StateCargando> = {
  set(_state, data: boolean) {
    _state.cargando = data;
  },
  setError(_state, data: boolean) {
    _state.error = data;
  },
};

const actions: ActionTree<StateCargando, State> = {
  set(ctx, data: boolean) {
    ctx.commit("set", data);
  },
  setError(ctx, data: boolean) {
    ctx.commit("setError", data);
  },
};

const cargando = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default cargando;
