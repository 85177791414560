import { ActionTree, GetterTree, MutationTree } from "vuex";
// firebase
import { Firebase } from "@/config/firebase";
// helpers
import { idtify } from "@/helpers/string";
import { log } from "@/helpers/env";
// composables
import { useRutas } from "@/composables/rutas";
//tipos
import {
  Usuarios,
  Usuario,
  TipoUsuario,
} from "@/typings/store/plugins/easyFirestore/usuarios";
import { State } from "@/typings/store";
import { Ruta } from "@/typings/store/modules/rutas";
import { UsuarioState } from "@/typings/store/modules/usuario";
import { Empresa } from "@/typings/store/plugins/easyFirestore/empresas";
import { Permisos } from "@/typings/store/modules/permisos";

const _rutas = useRutas();

const state: UsuarioState = {
  usuario: null,
  tipo: null,
  error: null,
  primerInicio: false,
  intentos: 0,
};

const getters: GetterTree<UsuarioState, State> = {
  get(_state, _getters, _rootState, _rootGetters) {
    const usuarios = _rootGetters["usuarios/get"] as Usuarios;
    const id = _state.usuario?.id;
    return id ? usuarios[id] : null;
  },
  getPermisos(_state, _getters): Permisos {
    const _usuario = _getters["get"] as Usuario | null;
    if (!_usuario?.permisos) {
      return {};
    }
    return _usuario.permisos;
  },
  getTipo(_state, _getters): TipoUsuario | null {
    const _usuario = _getters["get"] as Usuario | null;
    if (!_usuario) {
      return null;
    }
    if (!_state.tipo || _usuario.tipo != "sudo") {
      return _usuario.tipo;
    }
    return _state.tipo;
  },
  getRutas(_state, _getters): Array<Ruta> {
    const _usuario = _getters["get"] as Usuario | null;
    if (!_usuario) {
      return [];
    }
    // al configurar los tipo de usuario, eliminar el <?? "administrador"> de la linea de abajo
    const tipo = (_getters["getTipo"] as TipoUsuario | null) ?? "administrador";
    if (!tipo) {
      return [];
    }
    const permisos = _usuario ? _usuario.permisos : {};
    const permisosVerdaderos = Object.keys(permisos).filter((key) => {
      return permisos[key];
    });
    const rutas = _rutas.get.value;
    const mapRutas = permisosVerdaderos
      .map((item) => {
        const rutaDefault: Ruta = {
          text: item,
          id: "error",
          number: 999,
          mobile: true,
          desktop: true,
          tipos: [tipo],
          disabled: true,
          icon: "mdi-close-octagon",
        };
        return rutas[item] ?? Object.assign({}, rutas["home"], rutaDefault);
      })
      .filter((item) => {
        return item.id != "error";
      });
    mapRutas.sort((a, b) => {
      return a.number - b.number;
    });
    return mapRutas;
  },
  getEmpresa(_state, _getters, _rootState, _rootGetters): Empresa {
    const _usuario = _getters["get"] as Usuario | null;
    const defaultEmpresa: Empresa = {
      id: "11111111-1",
      nombre: "bestdata",
      rut: "11111111-1",
      logo: "",
      ciudad: "",
      giro: "",
      direccion: "",
      telefono: "",
      razonSocial: "",
      posicion: null,
      alertaBarredores: 10,
      fechaBarredores: null,
    };
    if (!_usuario) {
      return defaultEmpresa;
    }
    const empresas = _rootGetters["empresas/getArray"] as Empresa[];
    const empresa = empresas.find((item) => {
      if (!item.id || !_usuario.empresa) {
        return false;
      }
      return item.id == _usuario.empresa;
    });
    return empresa ?? defaultEmpresa;
  },
  getErrorSingIn(_state): string | null {
    return _state.error;
  },
  getPrimerInicio(_state): boolean {
    return _state.primerInicio;
  },
  getIntentos(_state): number {
    return _state.intentos;
  },
};

const mutations: MutationTree<UsuarioState> = {
  set(_state, data: Usuario | null) {
    _state.usuario = data;
  },
  setTipo(_state, data: TipoUsuario | null) {
    _state.tipo = data;
  },
  setError(_state, data: string | null) {
    _state.error = data;
  },
  setPrimerInicio(_state, data: boolean) {
    _state.primerInicio = data;
  },
  setIntentos(_state, data: number) {
    _state.intentos = data;
  },
};

const actions: ActionTree<UsuarioState, State> = {
  set(ctx, data: Usuario | null) {
    if (!data) {
      ctx.commit("set", null);
      return "";
    }
    ctx.commit("set", data);
    return data.id;
  },
  setTipo(ctx, data: TipoUsuario | null) {
    const _usuario = ctx.getters["get"] as Usuario | null;
    if (!data || _usuario?.tipo != "sudo") {
      ctx.commit("setTipo", null);
      return null;
    }
    ctx.commit("setTipo", data);
    return data;
  },
  signIn(ctx, data: { email: string; password: string }) {
    ctx.commit("setError", null);
    Firebase.auth()
      .signInWithEmailAndPassword(data.email, data.password)
      .catch(async (error) => {
        const id = idtify(data.email);
        await ctx
          .dispatch("usuarios/fetchById", id, { root: true })
          .catch((_error) => {
            if (log) {
              console.error("Usuario no encontrado => ", _error);
            }
          });
        const usuarios = ctx.rootGetters["usuarios/get"] as Usuarios;
        switch (error.code) {
          case "auth/invalid-email":
            ctx.commit("setError", "invalid-email");
            break;
          case "auth/user-disabled":
            ctx.commit("setError", "user-disabled");
            break;
          case "auth/user-not-found":
            if (usuarios[id]) {
              ctx.commit("setPrimerInicio", true);
            } else {
              ctx.commit("setError", "user-not-found");
            }
            break;
          case "auth/wrong-password":
            ctx.commit("setError", "wrong-password");
            ctx.commit("setIntentos", ctx.state.intentos + 1);
            break;
        }
      });
  },
  signOut(ctx) {
    Firebase.auth().signOut();
    ctx.commit("setTipo", null);
    ctx.commit("setError", null);
    ctx.commit("setPrimerInicio", false);
  },
  recuperarPassword(ctx, email: string) {
    ctx.commit("setError", null);
    return Firebase.auth()
      .sendPasswordResetEmail(email)
      .catch(() => {
        ctx.commit("setError", "invalid-email");
      });
  },
  activar(ctx, data: { email: string; password: string }) {
    ctx.commit("setError", null);
    return Firebase.auth()
      .createUserWithEmailAndPassword(data.email, data.password)
      .then(() => {
        ctx.commit("setError", null);
      })
      .catch(() => {
        ctx.commit("setError", "Usuario ya registrado");
      });
  },
};

const usuario = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default usuario;
