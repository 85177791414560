// composition-api
import { computed, ref } from "vue";

interface Data {
  peso?: number;
  tag?: string;
}

const peso = ref(0);
const tag = ref("");
const fecha = ref(new Date());
const sincronizar = ref(false);
const sincronizando = ref(false);

const actualizar = async () => {
  const respuesta = await fetch("https://localhost:3000/peso").catch(() => {
    return null;
  });
  if (!respuesta) return;
  const data: Data = await respuesta.json();
  const _peso = data.peso || 0;
  const _tag = data.tag || "";
  peso.value = _peso;
  tag.value = _tag;
  fecha.value = new Date();
};

setInterval(async () => {
  if (!sincronizando.value) {
    sincronizando.value = true;
    if (sincronizar.value) {
      await actualizar();
    }
    sincronizando.value = false;
  }
}, 1000);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useBascula() {
  const getPeso = computed(() => {
    return peso.value;
  });

  const getTag = computed(() => {
    return tag.value;
  });

  const getFecha = computed(() => {
    return fecha.value;
  });

  const getSincronizar = computed(() => {
    return sincronizar.value;
  });

  const setSincronizar = (value: boolean) => {
    sincronizar.value = value;
  };

  return {
    getPeso,
    getTag,
    getFecha,
    getSincronizar,
    setSincronizar,
  };
}
