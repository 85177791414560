import { ModuleTree } from "vuex";

import { State } from "@/typings/store/index";

// modules
import drawer from "./drawer";
import cargando from "./cargando";
import usuario from "./usuario";
import permisos from "./permisos";
import sw from "./sw";

const modules: ModuleTree<State> = {
  drawer,
  cargando,
  usuario,
  permisos,
  sw,
};

export default modules;
