import {
  PesajeData,
  PesajePatch,
} from "@/typings/store/plugins/easyFirestore/pesajes";

export const pesajeDataToPatch = (
  id: string,
  pesajeData: PesajeData
): PesajePatch => {
  const doc: PesajePatch = {
    id: id,
    sincronizacion: {
      enviado: new Date().toISOString(),
      recibido: null,
    },
  };
  if (pesajeData.vehiculo) {
    doc.vehiculo = pesajeData.vehiculo;
  }
  if (pesajeData.capacidad) {
    doc.capacidad = pesajeData.capacidad;
  }
  if (pesajeData.cobro) {
    doc.cobro = pesajeData.cobro;
  }
  if (pesajeData.ruta) {
    doc.ruta = pesajeData.ruta;
  }
  if (pesajeData.cliente) {
    doc.cliente = pesajeData.cliente;
  }
  if (pesajeData.desecho) {
    doc.desecho = pesajeData.desecho;
  }
  if (pesajeData.servicio) {
    doc.servicio = pesajeData.servicio;
  }
  if (pesajeData.choferEntrada) {
    doc.entrada = Object.assign({}, doc.entrada, {
      chofer: pesajeData.choferEntrada,
    });
  }
  if (pesajeData.fechaEntrada) {
    doc.entrada = Object.assign({}, doc.entrada, {
      fecha: pesajeData.fechaEntrada,
    });
  }
  if (pesajeData.pesoEntrada) {
    doc.entrada = Object.assign({}, doc.entrada, {
      peso: pesajeData.pesoEntrada,
    });
  }
  if (pesajeData.observacionEntrada) {
    doc.entrada = Object.assign({}, doc.entrada, {
      observacion: pesajeData.observacionEntrada,
    });
  }
  if (pesajeData.choferSalida) {
    doc.salida = Object.assign({}, doc.salida, {
      chofer: pesajeData.choferSalida,
    });
  }
  if (pesajeData.fechaSalida) {
    doc.salida = Object.assign({}, doc.salida, {
      fecha: pesajeData.fechaSalida,
    });
  }
  if (pesajeData.pesoSalida) {
    doc.salida = Object.assign({}, doc.salida, {
      peso: pesajeData.pesoSalida,
    });
  }
  if (pesajeData.observacionSalida) {
    doc.salida = Object.assign({}, doc.salida, {
      observacion: pesajeData.observacionSalida,
    });
  }
  if (pesajeData.estado) {
    doc.estado = pesajeData.estado;
  }
  return doc;
};
