import "vue-class-component/hooks";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import "./plugins/vue-the-mask";
import "./plugins/vue2-google-maps";
import "./plugins/vue-apexcharts";
import mixins from "@/mixins/mixins";

Vue.mixin(mixins);

Vue.config.productionTip = false;
Vue.prototype.$eventBus = new Vue();

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
