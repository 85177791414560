var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('GmapMarker',{attrs:{"position":_vm.ultimaPosicion.posicion,"icon":{
    url: _vm.icono,
    size: {
      width: _vm.size,
      height: _vm.size,
      f: 'px',
      b: 'px',
    },
    scaledSize: {
      width: _vm.size,
      height: _vm.size,
      f: 'px',
      b: 'px',
    },
  },"clickable":true},on:{"click":_vm.seleccionar}},[_c('GmapInfoWindow',{attrs:{"opened":_vm.infoWindow},on:{"update:opened":function($event){_vm.infoWindow=$event},"closeclick":function($event){_vm.infoWindow = false}}},[_c('span',{staticClass:"metropolis"},[_vm._v(_vm._s(_vm.name))]),(!_vm.historico && _vm.flagHorario)?_c('br'):_vm._e(),(!_vm.historico && _vm.flagHorario)?_c('span',{staticClass:"metropolis"},[_vm._v(_vm._s(_vm.estado))]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }