import {Barredor} from "@/typings/store/plugins/easyFirestore/barredores";

export default class BarredoresDesconectados {
  hibernando: Barredor[] = [];
  desconectados: Barredor[] = [];

  get length(): number {
    return this.hibernando.length + this.desconectados.length;
  }

  get ids(): string[] {
    const result: string[] = [];
    for (const barredor of this.desconectados) {
      result.push(barredor.id ?? "");
    }
    for (const barredor of this.hibernando) {
      result.push(barredor.id ?? "");
    }
    return result;
  }

  get all(): Barredor[] {
    return [
      ...this.hibernando,
      ...this.desconectados,
    ];
  }
}
