// vuex
import { Store } from "vuex";
// firebase
import { Firebase, initFirebase } from "@/config/firebase";
// tipos
import { State } from "@/typings/store";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
// helpers
import { log } from "@/helpers/env";
import { idtify } from "@/helpers/string";
// imports
import abrirCanalesBD from "./abrirCanalesBD";
import cerrarCanalesBD from "./cerrarCanalesBD";
import iniciarProcesos from "./iniciarProcesos";
import { Suscripcion } from "@/typings/store/plugins/easyFirestore/suscripciones";

const iniciarFirebase = (store: Store<State>): void => {
  initFirebase()
    .then(() => {
      Firebase.auth().onAuthStateChanged(async (user) => {
        if (log) console.time("testimonios/openDBChannel");
        await store.dispatch("testimonios/openDBChannel");
        if (log) console.timeEnd("testimonios/openDBChannel");
        store.commit("cargando/set", true);
        if (user) {
          if (log) {
            console.time("login");
            console.time("authUser");
          }
          if (user.email) {
            await store
              .dispatch("usuarios/fetchById", user.uid)
              .catch((error) => {
                if (log) {
                  console.error(error);
                }
              });
            const usuarioUID = store.getters["usuarios/get"][user.uid] as
              | Usuario
              | undefined;
            const id = idtify(user.email);
            await store.dispatch("usuarios/fetchById", id).catch((error) => {
              if (log) {
                console.error(error);
              }
            });
            const usuarioEmail = store.getters["usuarios/get"][id] as
              | Usuario
              | undefined;
            if (usuarioEmail) {
              const usuario = Object.assign({}, usuarioUID, usuarioEmail);
              usuario.id = user.uid;
              usuario.uid = user.uid;
              usuario.estado = "activo";
              await store.dispatch("usuarios/set", usuario);
              await store.dispatch("usuarios/delete", id);
            }
          }

          const usuario = store.getters["usuarios/get"][user.uid] as
            | Usuario
            | undefined;

          if (
            !usuario ||
            !usuario.permisos ||
            Object.values(usuario.permisos).length === 0
          ) {
            store.commit("usuario/setError", "invalid-user");
            store.commit("cargando/set", false);
            Firebase.auth().signOut();
            return;
          }

          if (usuario.estado !== "activo") {
            store.commit("usuario/setError", "user-disabled");
            store.commit("cargando/set", false);
            Firebase.auth().signOut();
            return;
          }

          await store.dispatch("suscripciones/fetchAndAdd", {
            where: [
              ["empresa.id", "==", usuario.empresa],
              ["valido", "==", true],
            ],
          });

          const suscripciones: Array<Suscripcion> =
            store.getters["suscripciones/getArray"];
          const suscripcionesOrdenadasDesc = suscripciones.slice();
          suscripcionesOrdenadasDesc.sort((a, b) => {
            const fechaA = a.fechaInicio.toDate();
            const fechaB = b.fechaInicio.toDate();
            return fechaB.getTime() - fechaA.getTime();
          });
          const ultimaSuscripcion = suscripcionesOrdenadasDesc[0];
          if (log) {
            console.log(ultimaSuscripcion);
          }
          if (!ultimaSuscripcion) {
            await Firebase.auth().signOut();
            store.commit("usuario/setError", "no-subscription");
            store.commit("cargando/set", false);
            return;
          } else {
            const fechaUltimaSuscripcion =
              ultimaSuscripcion.fechaInicio.toDate();
            fechaUltimaSuscripcion.setMonth(
              fechaUltimaSuscripcion.getMonth() + ultimaSuscripcion.duracion
            );
            if (log) {
              console.log(fechaUltimaSuscripcion);
            }
            const fechaActual = new Date();
            if (fechaActual.getTime() > fechaUltimaSuscripcion.getTime()) {
              await Firebase.auth().signOut();
              store.commit("usuario/setError", "expired-subscription");
              store.commit("cargando/set", false);
              return;
            }
          }
          await store.dispatch("suscripciones/openDBChannel", {
            where: [["id", "==", ultimaSuscripcion.id]],
          });
          if (log) {
            console.timeEnd("authUser");
          }

          store.commit("usuario/set", usuario);

          if (log) {
            console.time("abrirCanalesBD");
          }
          await abrirCanalesBD(store, usuario);
          if (log) {
            console.timeEnd("abrirCanalesBD");
          }

          if (log) {
            console.time("iniciarProcesos");
          }
          await iniciarProcesos(store, usuario);
          if (log) {
            console.timeEnd("iniciarProcesos");
            console.timeEnd("login");
          }
        } else {
          if (log) {
            console.log("logout");
          }
          const usuario = store.getters["usuario/get"];
          if (usuario) {
            await store.dispatch("suscripciones/closeDBChannel", {
              clearModule: true,
            });
            await cerrarCanalesBD(store, usuario);
          }
          store.commit("usuario/set", null);
        }
        setTimeout(() => {
          store.commit("cargando/set", false);
        }, 1000);
      });
    })
    .catch((error) => {
      if (log) {
        console.error(error);
      }
      store.dispatch("cargando/setError", true);
    });
};

export default iniciarFirebase;
