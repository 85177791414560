// composition-api
import {} from "vue";
// i18n
import i18n from "@/plugins/i18n";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useI18n() {
  const t = i18n.t.bind(i18n);
  const tc = i18n.tc.bind(i18n);

  const text = (key:string):string =>{
    return t(key) as string
  }

  return { t, tc ,text};
}
