// composition-api
import { computed, reactive, ref } from "vue";
// tipos
import { Camaras } from "@/typings/store/modules/camaras";

const getLocalStorageCamaras = () => {
  const _camaras = localStorage.getItem("camaras");
  if (_camaras) {
    return JSON.parse(_camaras) as Camaras;
  }
  return {
    ["cam01"]: {
      id: "cam01",
      img: "",
      ws: null,
      intervalId: null,
    },
  } as Camaras;
};

const camaras = reactive(getLocalStorageCamaras());
const ipServer = ref("localhost:3000");
const sincronizar = ref(false);
const monitor = ref<NodeJS.Timer | null>(null);

const iniciarCamara = (id: string) => {
  if (!camaras[id]) {
    console.error("camara no existe");
    return;
  }

  console.log("Intentando conectar con la camara id: ", id);
  const ws = new WebSocket(`wss://${ipServer.value}/${id}`);

  ws.onmessage = (event) => {
    const wsActual = camaras[id]?.ws;
    if (ws !== wsActual) {
      ws.close();
      return;
    }
    camaras[id].img = event.data;
  };

  const onOpen = () => {
    console.log("Conexion exitosa");
  };

  const onError = () => {
    console.log("Error en la conexion");
    if (camaras[id].ws) {
      camaras[id].ws?.close();
    }
  };

  const onClose = () => {
    console.log("Conexion cerrada");
    const _ws = camaras[id].ws;
    camaras[id].ws = null;
    if (_ws) {
      _ws.onopen = null;
      _ws.onmessage = null;
      _ws.onerror = null;
      _ws.onclose = null;
    }
  };

  ws.onopen = onOpen;

  ws.onerror = onError;

  ws.onclose = onClose;

  camaras[id].ws = ws;
};

const _monitor = () => {
  return setInterval(() => {
    if (sincronizar.value) {
      for (const id in camaras) {
        const ws = camaras[id].ws;
        if (!ws || ws.readyState === WebSocket.CLOSED) {
          iniciarCamara(id);
        }
      }
    }
  }, 10000);
};

monitor.value = _monitor();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useCamaras(data?: { id?: string }) {
  const _id = ref<string>(data?.id ?? "cam01");

  const get = computed(() => camaras);

  const getSincronizar = computed(() => {
    return sincronizar.value;
  });

  const setSincronizar = (value: boolean) => {
    sincronizar.value = value;
  };

  const setId = (__id: string) => {
    _id.value = __id;
  };

  const getImagen = computed(() => get.value[_id.value].img);

  const nextCamara = () => {
    const camarasKeys = Object.keys(camaras);
    const index = camarasKeys.indexOf(_id.value);
    if (index === -1) {
      return;
    }
    let nextIndex = index + 1;
    if (nextIndex >= camarasKeys.length) {
      nextIndex = 0;
    }
    _id.value = camarasKeys[nextIndex];
  };

  return {
    get,
    getSincronizar,
    setSincronizar,
    setId,
    getImagen,
    nextCamara,
  };
}
