var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"row-buttons flex-grow-0",attrs:{"no-gutters":"","align":"end"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{class:{
        'rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button': true,
        'disabled-light--text tab-inactive': _vm.tab !== 0
      },attrs:{"block":"","height":_vm.tab === 0 ? '50px' : '44px',"depressed":"","color":_vm.tab==0 ?'yellow':'disabled'},on:{"click":function($event){_vm.tab = 0}}},[_vm._v(" "+_vm._s(_vm.$t("barredores.tab.barredores.text"))+" ")])],1),(_vm.isAdmin)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{class:{
        'rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button': true,
        'disabled-light--text tab-inactive': _vm.tab !== 1
      },attrs:{"color":_vm.tab==1 ? 'purple':'disabled',"block":"","height":_vm.tab === 1 ? '50px' : '44px',"depressed":""},on:{"click":function($event){_vm.tab = 1}}},[_vm._v(" "+_vm._s(_vm.$t("barredores.tab.rutas.text"))+" ")])],1):_vm._e(),(_vm.isAdmin)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{class:{
        'rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button': true,
        ' disabled-light--text tab-inactive': _vm.tab !== 2
      },attrs:{"color":_vm.tab==2 ? 'primary':'disabled',"block":"","height":_vm.tab === 2 ? '50px' : '44px',"depressed":""},on:{"click":function($event){_vm.tab = 2}}},[_vm._v(" "+_vm._s(_vm.$t("barredores.tab.monitoreo.text"))+" ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }