//tipos
import { GetterTree } from "vuex";
import { State } from "@/typings/store";
import { StateRutasBarredor } from "@/typings/store/plugins/easyFirestore/rutasBarredor";

const getters: GetterTree<StateRutasBarredor, State> = {
  get(state) {
    return state.all;
  },
  getArray(state) {
    return Object.values(state.all);
  },
};

const rutasBarredor = {
  firestorePath: "{env}/v1/empresas/{idEmpresa}/rutasBarredor",
  firestoreRefType: "collection",
  moduleName: "rutasBarredor",
  statePropName: "all",
  namespaced: true,

  state: {},
  getters,
  mutations: {},
  actions: {},
};

export default rutasBarredor;
