var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column fill-height pa-4"},[_c('v-row',{staticClass:"flex-grow-0",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"font-weight-bold darker--text metropolis text-body-1",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.$t("barredores.tab.monitoreo.select.label"))+" ")]),_c('v-col',[_c('v-select',{staticClass:"rounded-input elevation-input metropolis",attrs:{"hide-details":"","dense":"","flat":"","solo":"","placeholder":_vm.$t('barredores.tab.monitoreo.select.placeholder'),"items":_vm.barredoresOrdenados,"return-object":"","item-value":"id","menu-props":{
          contentClass: 'rounded-input elevation-black',
          transition: 'slide-y-transition',
        }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{staticClass:"metropolis text-body-1 darker--text text-capitalize"},[_vm._v(" "+_vm._s(item.numero)+" - "+_vm._s(item.nombre)+" ")])]}},{key:"selection",fn:function({ item }){return [_c('span',{staticClass:"metropolis text-body-1 darker--text text-capitalize"},[_vm._v(" "+_vm._s(item.numero)+" - "+_vm._s(item.nombre)+" ")])]}}]),model:{value:(_vm.dataMonitoreo.barredor),callback:function ($$v) {_vm.$set(_vm.dataMonitoreo, "barredor", $$v)},expression:"dataMonitoreo.barredor"}})],1)],1),(_vm.dataMonitoreo.barredor)?_c('v-row',{staticClass:"row-buttons flex-grow-0 mt-3",attrs:{"no-gutters":"","align":"end"}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-btn',{class:{
          'rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button': true,
          'button-inactive': _vm.tab !== 0,
        },attrs:{"block":"","height":_vm.tab === 0 ? '50px' : '44px',"depressed":"","color":"secondary"},on:{"click":function($event){_vm.tab = 0}}},[_vm._v(" "+_vm._s(_vm.$t("barredores.tab.monitoreo.tab.sin-movimiento.text"))+" ")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-btn',{class:{
          'rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button': true,
          'button-inactive': _vm.tab !== 1,
        },attrs:{"color":"primary","block":"","height":_vm.tab === 1 ? '50px' : '44px',"depressed":""},on:{"click":function($event){_vm.tab = 1}}},[_vm._v(" "+_vm._s(_vm.$t("barredores.tab.monitoreo.tab.ruta.text"))+" ")])],1)],1):_vm._e(),(_vm.dataMonitoreo.barredor)?_c('v-row',{staticClass:"pb-3",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card',{staticClass:"elevation-black rounded-input rounded-tl-0",attrs:{"height":"100%"}},[_c('v-card-text',{staticClass:"fill-height"},[_c('v-tabs-items',{staticClass:"fill-height",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"fill-height"},[_c('SinMovimiento',{attrs:{"pings-filtrados":_vm.pingsFiltrados}})],1),_c('v-tab-item',{staticClass:"fill-height"},[_c('RutaMonitoreo',{attrs:{"pings-filtrados":_vm.pingsFiltrados,"ping-actual":_vm.pingActual},on:{"click:eliminar-ping":_vm.onClickEliminarPing,"click:eliminar-ruta-completa":_vm.onClickEliminarRutaCompleta}})],1)],1)],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }