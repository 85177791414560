import VuexEasyFirestore from "vuex-easy-firestore";

import { Firebase } from "@/config/firebase";

import usuarios from "./usuarios";
import vehiculos from "./vehiculos";
import choferes from "./choferes";
import clientes from "./clientes";
import desechos from "./desechos";
import pesajes from "./pesajes";
import identificadores from "./identificadores";
import servicios from "./servicios";
import rutasPesaje from "./rutasPesaje";
import empresas from "./empresas";
import barredores from "./barredores";
import rastreadores from "./rastreadores";
import rutasBarredor from "./rutasBarredor";
import archivos from "./archivos";
import pingsRastreador from "./pingsRastreador";
import suscripciones from "@/store/plugins/easyFirestore/suscripciones";
import testimonios from "@/store/plugins/easyFirestore/testimonios";
import mail from "@/store/plugins/easyFirestore/mail";
import planes from "@/store/plugins/easyFirestore/planes";

const easyFirestore = VuexEasyFirestore(
  [
    usuarios,
    vehiculos,
    choferes,
    clientes,
    desechos,
    pesajes,
    identificadores,
    servicios,
    rutasPesaje,
    barredores,
    rastreadores,
    pingsRastreador,
    rutasBarredor,
    empresas,
    archivos,
    suscripciones,
    testimonios,
    mail,
    planes,
  ],
  {
    logging: true,
    FirebaseDependency: Firebase,
  }
);

export default easyFirestore;
