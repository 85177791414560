//tipos
import { GetterTree } from "vuex";
import { State } from "@/typings/store/index";
import {
  Cliente,
  Clientes,
  StateClientes,
} from "@/typings/store/plugins/easyFirestore/clientes";

const getters: GetterTree<StateClientes, State> = {
  get(state, getters) {
    return (getters["getArray"] as Array<Cliente>).reduce((docs, doc) => {
      if (!doc?.id) {
        console.error("doc.id == undefined => ", doc);
        return docs;
      }
      docs[doc.id] = doc;
      return docs;
    }, {} as Clientes);
  },
  getArray(state) {
    const arrayClientes = Object.values(state.all).map((doc) => {
      if (!doc.estado) {
        doc.estado = "habilitado";
      }
      return JSON.parse(JSON.stringify(doc)) as Cliente;
    });
    arrayClientes.sort((a, b) => {
      return a.nombre.localeCompare(b.nombre);
    });
    return arrayClientes;
  },
};

const clientes = {
  firestorePath: "{env}/v1/empresas/{idEmpresa}/clientes",
  firestoreRefType: "collection",
  moduleName: "clientes",
  statePropName: "all",
  namespaced: true,

  state: {},
  getters,
  mutations: {},
  actions: {},
};

export default clientes;
