// composition-api
import { computed } from "vue";
// vuex
import store from "@/store";
// tipos
import {
  Usuario,
  Usuarios,
} from "@/typings/store/plugins/easyFirestore/usuarios";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useUsuarios() {
  const get = computed(() => {
    return store.getters["usuarios/get"] as Usuarios;
  });

  const getArray = computed(() => {
    return store.getters["usuarios/getArray"] as Usuario[];
  });

  return { get, getArray };
}
