// vuex
import {ActionTree, GetterTree, MutationTree} from "vuex";
// tipos
import {State} from "@/typings/store/index";
import {StatePermisos, Permisos} from "@/typings/store/modules/permisos";
import { useRutas } from "@/composables/rutas";


const state: StatePermisos = {};

const getters: GetterTree<StatePermisos, State> = {
  getTipoUsuario() {
    const rutas = useRutas().getArray.value;
    const permisosTipoUsuario: { [key: string]: Permisos } = {
      administrador: {},
      supervisor: {},
      bascula: {},
      cliente: {},
      sudo: {}
    };

    rutas.forEach((ruta) => {
      if (ruta.tipos.length === 0) {
        permisosTipoUsuario["administrador"][ruta.id] = "eliminar";
        permisosTipoUsuario["bascula"][ruta.id] = "eliminar";
        if (ruta.id === "barredores") {
          permisosTipoUsuario["supervisor"][ruta.id] = "ver";
          permisosTipoUsuario["cliente"][ruta.id] = "ver";
        } else {
          permisosTipoUsuario["supervisor"][ruta.id] = "eliminar";
          permisosTipoUsuario["cliente"][ruta.id] = "eliminar";
        }
        return;
      }
      ruta.tipos.forEach((tipo) => {
        if ((tipo === "supervisor" || tipo === "cliente") && ruta.id === "barredores") {
          permisosTipoUsuario[tipo][ruta.id] = "ver";
        } else {
          permisosTipoUsuario[tipo][ruta.id] = "eliminar";
        }
      });
    });
    return permisosTipoUsuario;
  }
};

const mutations: MutationTree<StatePermisos> = {};

const actions: ActionTree<StatePermisos, State> = {};

const permisos = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

export default permisos;
