//tipos
import PouchDB from "pouchdb";
import { env, log } from "@/helpers/env";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RangoCache, State } from "@/typings/store/index";
import { Empresa } from "@/typings/store/plugins/easyFirestore/empresas";
import {
  PingRastreador,
  PingRastreadorCache,
  PingsRastreador,
  StatePingsRastreador,
} from "@/typings/store/plugins/easyFirestore/pingsRastreador";

const refBase = `${env}/v1/empresas`;

const state: StatePingsRastreador = {
  all: {},
  cache: null,
  pouchDB: null,
};

const getters: GetterTree<StatePingsRastreador, State> = {
  get(state, getters) {
    return (getters["getArray"] as Array<PingRastreador>).reduce(
      (docs, doc) => {
        if (!doc?.id) {
          console.error("doc.id == undefined => ", doc);
          return docs;
        }
        docs[doc.id] = doc;
        return docs;
      },
      {} as PingsRastreador
    );
  },
  getArray(state) {
    return Object.values(state.all).map((doc) => {
      if (isNaN(doc.posicion.lat) || isNaN(doc.posicion.lng)) {
        doc.posicion = { lat: 0, lng: 0 };
        doc.valido = false;
      }
      return JSON.parse(JSON.stringify(doc)) as PingsRastreador;
    });
  },
  getRangoCache(state) {
    return state.cache ?? { fechas: [] };
  },
};

const mutations: MutationTree<StatePingsRastreador> = {
  setAll(state, data: PingsRastreador) {
    state.all = Object.assign({}, data, state.all);
  },
  setRangoCache(state, cache: RangoCache) {
    if (!cache.fechas) {
      return;
    }
    state.cache = cache;
  },
  setCacheDB(state, db: PouchDB.Database<PingRastreadorCache>) {
    state.pouchDB = db ?? null;
  },
};

const actions: ActionTree<StatePingsRastreador, State> = {
  async limpiarCache(ctx) {
    const fecha = new Date();
    fecha.setDate(fecha.getDate() - 101);
    fecha.setHours(0, 0, 0, 0);
    const promesas: Promise<void>[] = [];
    for (let index = 0; index < 500; index++) {
      const promesa = ctx.dispatch("deleteRegistrosCache", fecha);
      promesas.push(promesa);
      fecha.setDate(fecha.getDate() - 1);
    }
    await Promise.all(promesas);
  },
  async limpiarRegistros(ctx) {
    const fecha = new Date();
    fecha.setHours(0, 0, 0, 0);
    ctx.commit("setRangoCache", { fechas: [] });
    for (let index = 0; index < 100; index++) {
      await ctx.dispatch("deleteRegistrosCache", fecha);
      fecha.setDate(fecha.getDate() - 1);
    }
  },
  async cargarRegistros(ctx, data: RangoCache) {
    const cache = ctx.getters.getRangoCache as RangoCache;
    const fecha = new Date();
    fecha.setHours(0, 0, 0, 0);
    const inicioLocal = new Date(fecha);
    inicioLocal.setDate(inicioLocal.getDate() - 100);
    inicioLocal.setHours(0, 0, 0, 0);
    const finLocal = new Date(fecha);
    finLocal.setHours(0, 0, 0, 0);
    for (const actual of data.fechas) {
      const inicio = new Date(actual);
      inicio.setHours(0, 0, 0, 0);
      if (inicio.getTime() >= fecha.getTime()) {
        if (log) {
          console.error("la fecha corresponde al dia de hoy", inicio);
        }
        continue;
      }
      if (cache.fechas.some((f) => f.getTime() === inicio.getTime())) {
        if (log) {
          console.log("la fecha ya esta en cache", inicio);
        }
        continue;
      }
      let registrosCache: PingsRastreador | null = await ctx.dispatch(
        "getRegistrosCache",
        inicio
      );
      if (log) {
        console.log("registrosCache", registrosCache);
      }
      if (registrosCache) {
        cache.fechas.push(inicio);
        ctx.commit("setRangoCache", cache);
        ctx.commit("setAll", registrosCache);
        continue;
      }
      const fin = new Date(inicio);
      fin.setDate(fin.getDate() + 1);
      console.log(
        `Cargando registros barredores del ${inicio.toLocaleDateString()}`
      );
      let resultado = {
        done: false,
        error: false,
      };
      while (resultado?.done !== true) {
        resultado = await ctx
          .dispatch("fetchAndAdd", {
            where: [
              ["fecha", ">=", inicio.toISOString()],
              ["fecha", "<", fin.toISOString()],
            ],
            limit: 5000,
          })
          .catch((err) => {
            console.error(err);
            return { done: true, error: true };
          });
      }
      if (resultado.error === true) {
        continue;
      }
      registrosCache = (await ctx.dispatch(
        "getRegistros",
        inicio
      )) as PingsRastreador | null;
      cache.fechas.push(inicio);
      await ctx.dispatch("setRegistrosCache", {
        registros: registrosCache,
        date: inicio,
      });
      ctx.commit("setRangoCache", cache);
      console.log(
        `cargado cache => fechas`,
        cache.fechas.map((f) => f.toLocaleDateString())
      );
    }
  },
  async getRefCacheDB(ctx) {
    const empresa = ctx.rootGetters["usuario/getEmpresa"] as Empresa;
    const idEmpresa = empresa.id ?? empresa.rut ?? "11111111-1";
    return `${refBase}/${idEmpresa}/pingsRastreador`;
  },
  async initCacheDB(ctx) {
    const ref = await ctx.dispatch("getRefCacheDB");
    const db = new PouchDB<PingRastreadorCache>(ref);
    ctx.commit("setCacheDB", db);
  },
  async updateRegistrosCache(
    ctx,
    data: {
      registros: PingsRastreador;
      date: Date;
      tipo: "eliminar" | "agregar";
    }
  ) {
    const { registros, date, tipo } = data;
    if (!registros || !date) {
      console.error("No hay datos para guardar en cache");
      return;
    }
    if (!ctx.state.pouchDB) await ctx.dispatch("initCacheDB");
    if (!ctx.state.pouchDB) {
      if (log) {
        console.error("Error al iniciar la base de datos pouchDB");
      }
      return;
    }
    const fecha = new Date(date);
    fecha.setHours(0, 0, 0, 0);
    const id = fecha.getTime().toString();
    try {
      const docOld = (await ctx.state.pouchDB.get(id)) as PingRastreadorCache;
      let registrosUpdate: PingsRastreador;
      if (tipo === "agregar") {
        registrosUpdate = {
          ...docOld.registros,
          ...registros,
        };
      } else {
        registrosUpdate = {};
        const registrosOld = docOld.registros;
        for (const key in registrosOld) {
          if (!registros[key]) {
            registrosUpdate[key] = registrosOld[key];
          }
        }
      }
      const doc: PingRastreadorCache = {
        _id: id,
        _rev: docOld._rev,
        _delete: docOld._delete,
        registros: registrosUpdate,
      };
      await ctx.state.pouchDB.put(doc).catch(async (err) => {
        ctx.state.pouchDB?.close();
        await ctx.dispatch("initCacheDB");
        console.error("error guardar registros cache", err);
      });
    } catch (error) {
      console.log(`No existe el registro ${id}`);
    }
  },
  async setRegistrosCache(
    ctx,
    data: { registros: PingsRastreador; date: Date }
  ) {
    const { registros, date } = data;
    if (!registros || !date) {
      console.error("No hay datos para guardar en cache");
      return;
    }
    if (!ctx.state.pouchDB) {
      await ctx.dispatch("initCacheDB");
    }
    if (!ctx.state.pouchDB) {
      if (log) {
        console.error("Error al iniciar la base de datos pouchDB");
      }
      return;
    }
    const fecha = new Date(date);
    fecha.setHours(0, 0, 0, 0);
    const id = fecha.getTime().toString();
    const doc: PingRastreadorCache = {
      _id: id,
      registros: registros,
    };
    await ctx.state.pouchDB.put(doc).catch(async (err) => {
      ctx.state.pouchDB?.close();
      await ctx.dispatch("initCacheDB");
      console.error("error guardar registros cache", err);
    });
  },
  async getRegistrosCache(ctx, data: Date) {
    if (!ctx.state.pouchDB) {
      await ctx.dispatch("initCacheDB");
    }
    if (!ctx.state.pouchDB) {
      if (log) {
        console.error("Error al iniciar la base de datos pouchDB");
      }
      return null;
    }
    const fecha = new Date(data);
    fecha.setHours(0, 0, 0, 0);
    const doc: PingRastreadorCache | null = await ctx.state.pouchDB
      .get(`${fecha.getTime()}`)
      .catch(async () => {
        ctx.state.pouchDB?.close();
        await ctx.dispatch("initCacheDB");
        return null;
      });
    if (!doc) {
      return null;
    }
    return doc.registros;
  },
  async deleteRegistrosCache(ctx, data: Date) {
    if (!ctx.state.pouchDB) {
      await ctx.dispatch("initCacheDB");
    }
    if (!ctx.state.pouchDB) {
      if (log) {
        console.error("Error al iniciar la base de datos pouchDB");
      }
      return;
    }
    const fecha = new Date(data);
    fecha.setHours(0, 0, 0, 0);
    const doc = (await ctx.state.pouchDB
      .get(`${fecha.getTime()}`)
      .catch(async () => {
        ctx.state.pouchDB?.close();
        await ctx.dispatch("initCacheDB");
        return null;
      })) as PingRastreadorCache | null;
    if (!doc || !doc._rev) {
      return;
    }
    await ctx.state.pouchDB.remove(doc._id, doc._rev).catch(async (err) => {
      ctx.state.pouchDB?.close();
      await ctx.dispatch("initCacheDB");
      console.error("error eliminar registros cache", err);
    });
  },
  async getRegistros(ctx, data: Date) {
    const fecha = new Date(data);
    fecha.setHours(0, 0, 0, 0);
    const registros = ctx.getters.getArray as PingRastreador[];
    const pingsRastreador: PingsRastreador = {};
    registros.forEach((registro) => {
      const fechaRegistro = new Date(registro.fecha);
      fechaRegistro.setHours(0, 0, 0, 0);
      const valido = fechaRegistro.getTime() === fecha.getTime();
      if (valido && registro.id) {
        pingsRastreador[registro.id] = registro;
      }
    });
    return pingsRastreador;
  },
};

const pingsRastreador = {
  firestorePath: "{env}/v1/empresas/{idEmpresa}/pingsRastreador",
  firestoreRefType: "collection",
  moduleName: "pingsRastreador",
  statePropName: "all",
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default pingsRastreador;
