var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{attrs:{"justify":"center","align":"start","no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{staticClass:"pt-3",attrs:{"no-gutters":"","align":"center","justify":"space-between"}},[_c('v-col',{class:{
          'metropolis font-weight-bold': true,
          'white--text': _vm.dialog,
          'darker--text': !_vm.dialog
        },attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),(_vm.required)?_c('v-col',{staticClass:"pa-0 error--text metropolis",attrs:{"cols":"auto"}},[_vm._v(" * ")]):_vm._e()],1)],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"9"}},[_c('v-textarea',{class:{
        'rounded-input metropolis': true,
        'elevation-input-dense': !_vm.dialog,
        'border-input': _vm.dialog
      },attrs:{"solo":!_vm.dialog,"outlined":_vm.dialog,"dense":"","hide-details":"auto","dark":_vm.dialog,"placeholder":_vm.placeholder,"error-messages":_vm.errorMessages,"readonly":_vm.readonly,"disabled":_vm.disabled,"auto-grow":_vm.autoGrow,"no-resize":_vm.noResize,"rows":_vm.rows},on:{"blur":_vm.onBlur,"focus":_vm.onFocus,"change":_vm.onChange,"input":_vm.onInput},model:{value:(_vm.vModel),callback:function ($$v) {_vm.vModel=$$v},expression:"vModel"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }