// composition-api
import { computed } from "vue";
// vuex
import store from "@/store";
// tipos
import {
  Suscripcion,
  Suscripciones,
} from "@/typings/store/plugins/easyFirestore/suscripciones";

const get = computed(() => {
  return store.getters["suscripciones/get"] as Suscripciones;
});

const getArray = computed(() => {
  return store.getters["suscripciones/getArray"] as Suscripcion[];
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useSuscripciones() {
  return { get, getArray };
}
