//tipos
import { GetterTree } from "vuex";
import { State } from "@/typings/store/index";
import { StatePlanes } from "@/typings/store/plugins/easyFirestore/planes";

const getters: GetterTree<StatePlanes, State> = {
  get(state) {
    return state.all;
  },
  getArray(state) {
    return Object.values(state.all);
  },
};

const planes = {
  firestorePath: "planes",
  firestoreRefType: "collection",
  moduleName: "planes",
  statePropName: "all",
  namespaced: true,

  state: {},
  getters,
  mutations: {},
  actions: {},
};

export default planes;
