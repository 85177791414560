//tipos
import { GetterTree } from "vuex";
import { State } from "@/typings/store/index";
import {
  Empresa,
  Empresas,
  StateEmpresas,
} from "@/typings/store/plugins/easyFirestore/empresas";

const getters: GetterTree<StateEmpresas, State> = {
  get(state, getters) {
    return (getters["getArray"] as Array<Empresa>).reduce((docs, doc) => {
      if (!doc?.id) {
        console.error("doc.id == undefined => ", doc);
        return docs;
      }
      docs[doc.id] = doc;
      return docs;
    }, {} as Empresas);
  },
  getArray(state) {
    return Object.values(state.all).map((doc) => {
      return JSON.parse(JSON.stringify(doc)) as Empresa;
    });
  },
};

const empresas = {
  firestorePath: "{env}/v1/empresas",
  firestoreRefType: "collection",
  moduleName: "empresas",
  statePropName: "all",
  namespaced: true,

  state: {},
  getters,
  mutations: {},
  actions: {},
};

export default empresas;
