// composition-api
import { ref, watch } from "vue";
import { Pesaje } from "@/typings/store/plugins/easyFirestore/pesajes";
import { usePesajes } from "./pesajes";
import { log } from "@/helpers/env";

const pesajes = usePesajes();

const filtrarPesajes = (pesajes: Array<Pesaje>): Array<Pesaje> => {
  const hoy = new Date();
  const inicio = new Date();
  const fin = new Date();
  const fechaInicioFiltro = new Date();
  const fechaFinFiltro = new Date();
  fechaInicioFiltro.setDate(fechaInicioFiltro.getDate() - 1);
  fechaInicioFiltro.setHours(23, 40, 0, 0);
  fechaFinFiltro.setHours(1, 0, 0, 0);
  if (hoy >= fechaInicioFiltro && hoy <= fechaFinFiltro) {
    inicio.setDate(inicio.getDate() - 1);
    inicio.setHours(23, 0, 0, 0);
  } else {
    inicio.setHours(0, 0, 0, 0);
  }
  fin.setHours(23, 59, 59, 999);
  return pesajes.filter((_pesaje) => {
    const fecha = _pesaje.entrada.fecha.toDate();
    const filtroEstado = _pesaje.estado !== "anulado";
    return fecha >= inicio && fecha <= fin && filtroEstado;
  });
};

const ordenarPesajes = (pesajes: Array<Pesaje>): Array<Pesaje> => {
  pesajes.sort((p1, p2) => {
    const entradaDateP1: Date | null = p1.entrada.fecha
      ? p1.entrada.fecha.toDate()
      : null;
    const entradaDateP2: Date | null = p2.entrada.fecha
      ? p2.entrada.fecha.toDate()
      : null;
    if (!entradaDateP1) return -1;
    if (!entradaDateP2) return 1;
    return entradaDateP2.getTime() - entradaDateP1.getTime();
  });
  return pesajes;
};

const arrayPesajesActualizado = ref(true);

watch(pesajes.getArray, () => {
  arrayPesajesActualizado.value = true;
});

const datosFiltrados = ref<Pesaje[]>([]);

const procesandoDatosFiltrados = ref(false);

setInterval(() => {
  if (!procesandoDatosFiltrados.value && arrayPesajesActualizado.value) {
    arrayPesajesActualizado.value = false;
    procesandoDatosFiltrados.value = true;
    if (log) {
      console.time("datosFiltrados");
    }
    let resultado = pesajes.getArray.value.slice();
    resultado = filtrarPesajes(resultado);
    resultado = ordenarPesajes(resultado);
    datosFiltrados.value = resultado;
    if (log) {
      console.timeEnd("datosFiltrados");
    }
    procesandoDatosFiltrados.value = false;
  }
}, 1000);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useVistaBascula() {
  return { datosFiltrados };
}
