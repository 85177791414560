import {GetterTree} from "vuex";
import {State} from "@/typings/store";
import {StatePesajes} from "@/typings/store/plugins/easyFirestore/pesajes";

const getters: GetterTree<StatePesajes, State> = {
  get(state) {
    return state.all;
  },
  getArray(state) {
    return Object.values(state.all);
  },
};

const pesajes = {
  firestorePath: "{env}/v1/empresas/{idEmpresa}/pesajes",
  firestoreRefType: "collection",
  moduleName: "pesajes",
  statePropName: "all",
  namespaced: true,
  serverChange: {
    convertTimestamps: {
      created_at: "%convertTimestamp%", // default
      updated_at: "%convertTimestamp%", // default
    },
  },
  state: {},
  getters,
  mutations: {},
  actions: {},
};

export default pesajes;
