var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('GmapMap',{staticClass:"google-map",attrs:{"zoom":_vm.zoom,"center":_vm.mapCenter,"map-type-id":"terrain","options":{
      zoomControl: true,
      scaleControl: false,
      mapTypeControl: true,
      rotateControl: false,
      fullscreenControl: true,
      streetViewControl: false,
      styles: require('@/assets/json/gmap/MapStyles.json'),
    }},on:{"update:zoom":function($event){_vm.zoom=$event}}},[_c('MapMarkers',{attrs:{"desconectados":_vm.desconectados,"barredores":_vm.barredoresValidos,"data-alertas-barredores":_vm.dataAlertasBarredores}})],1),_c('DialogoDesconectados',{attrs:{"desconectados":_vm.desconectados},model:{value:(_vm.dialogoDesconectados),callback:function ($$v) {_vm.dialogoDesconectados=$$v},expression:"dialogoDesconectados"}}),_c('AlertaBarredores',{attrs:{"desconectados":_vm.desconectados},on:{"click:alerta":function($event){_vm.dialogoDesconectados = true}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }