import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";

Vue.use(Vuex);

import plugins from "./plugins";
import modules from "./modules";

import iniciarFirebase from "./plugins/easyFirestore/iniciar";
import { State } from "@/typings/store/index";

const storeData: StoreOptions<State> = {
  state: {},
  plugins,
  modules,
};

const store = new Vuex.Store(storeData);

// initFirebase
iniciarFirebase(store);

export default store;
