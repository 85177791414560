// composition-api
import { computed, ref } from "vue";
// vuex
import store from "@/store";
// tipos
import {
  RutaPesaje,
  RutasPesaje,
} from "@/typings/store/plugins/easyFirestore/rutasPesaje";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useRutasPesaje(config?: { ordenarArray?: boolean }) {
  const ordenar = ref(config?.ordenarArray ?? false);

  const setOrdenarArray = (value: boolean) => {
    ordenar.value = value;
  };

  const get = computed(() => {
    return store.getters["rutasPesaje/get"] as RutasPesaje;
  });

  const sort = (array: RutaPesaje[]) => {
    array.sort((ruta1, ruta2) => {
      return ruta1.nombre.localeCompare(ruta2.nombre, "es", {
        numeric: true,
        sensitivity: "base",
      });
    });
    return array;
  };

  const getArray = computed(() => {
    let rutasPesaje = store.getters["rutasPesaje/getArray"] as RutaPesaje[];
    if (ordenar.value) {
      rutasPesaje = sort(rutasPesaje);
    }
    return rutasPesaje;
  });

  const getArrayHabilitados = computed(() => {
    return getArray.value.filter((chofer) => chofer.estado === "habilitado");
  });

  return { get, getArray, getArrayHabilitados, setOrdenarArray };
}
