// composition-api
import { computed, ref } from "vue";
// vuex
import store from "@/store";
// tipos
import {
  Desecho,
  Desechos,
} from "@/typings/store/plugins/easyFirestore/desechos";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useDesechos(config?: { ordenarArray?: boolean }) {
  const ordenar = ref(config?.ordenarArray ?? false);

  const setOrdenarArray = (value: boolean) => {
    ordenar.value = value;
  };

  const get = computed(() => {
    return store.getters["desechos/get"] as Desechos;
  });

  const sort = (array: Desecho[]) => {
    array.sort((a, b) => {
      return a.nombre.localeCompare(b.nombre);
    });
    return array;
  };

  const getArray = computed(() => {
    let desechos = store.getters["desechos/getArray"] as Desecho[];
    if (ordenar.value) {
      desechos = sort(desechos);
    }
    return desechos;
  });

  return { get, getArray, setOrdenarArray };
}
