// conpresor imagen
import Compressor from "compressorjs";

interface OptionsImageToWebp {
  quality: number;
  maxWidth?: number;
  maxHeight?: number;
}

export const imageToWebp = (
  file: File | Blob,
  options?: OptionsImageToWebp
): Promise<File | Blob | null> => {
  return new Promise<File | Blob | null>((resolve) => {
    new Compressor(file, {
      quality: options?.quality ?? 0.85,
      mimeType: "image/webp",
      maxWidth: options?.maxWidth ?? 1920,
      maxHeight: options?.maxHeight ?? 1024,
      async success(result) {
        resolve(result);
      },
      error(err) {
        console.log(err.message);
        resolve(null);
      },
    });
  });
};
